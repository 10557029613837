import axios from 'axios';
import { Database } from '../Database/database';

// Hook
export async function cacheCookies() {

    const UrlConsulta = Database();
    const baseURL = UrlConsulta + "/consulta/cnae/";

    await Cnae()
    async function Cnae() {
        let verificarTabela = localStorage.getItem("tableCnae");
        let verificarTab: any = verificarTabela ? verificarTabela : undefined;

        if (verificarTab === "undefined") {
            localStorage.setItem("tableCnae", "{}");
        }

        if (!verificarTab || verificarTab.typeof === null) {
            await axios.get(baseURL)
                .then(response => {
                    localStorage.setItem("tableCnae", JSON.stringify(response.data as any));
                })

        }
    }

}

export default cacheCookies;
