import { Box, SvgIcon, SvgIconProps, SvgIconTypeMap } from "@mui/material"

interface IProps {
    name?: string,
    url?: string,
    site?: string,
    Icon: SvgIconProps,
    Width?: number,
    Height?: number
}

const LinkIcon = ({name, url ,site, Icon, Width = 30, Height = 30}: IProps ) =>{

    let social = site? `site:${site}`: "";
    let Url = url? url : `https://www.google.com/search?q=${name}`
    return(
    <a target="_blank" href={`${Url} ${social}`}>
        <SvgIcon sx={{color: "#000", margin: "0 5px 0px 0px !important", "&:hover": {color: "rgb(231, 107, 58)"}}
}
    width={Width}
    height={Height}
    >
            {Icon}
        </SvgIcon>
    </a>
    )
}



export { LinkIcon }