import { KeyboardEvent } from 'react'
import { IBoardData } from '../../../hooks/Context'

export const editFunction = async (id: string | number, e: KeyboardEvent<HTMLDivElement>, newBoard: IBoardData[], setEditBoard: Function): Promise<IBoardData[] | undefined> => {

  if (e.key == 'Enter') {
    const val = (document.getElementById("boardEditForm") as HTMLInputElement).value
    const boardId = id

    if (boardId !== null) {
      let newBoardData = await JSON.parse(JSON.stringify(newBoard))
      //let newBoardData = JSON.parse(JSON.stringify(newBoard));
      await newBoardData.find((board: IBoardData) => board.id === id && (board.name = val.trim()))
      setEditBoard(false)

      return newBoardData
    }
  }
}