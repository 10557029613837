import logoB2B from "../../image/NewLogo.png";
import capaB2B from "../../image/capalogin.png";
import FundoB2B from "../../image/FundoImage.png";
import LogoBlack from "../../image/LogoBlack.png";

const B2bfullUrl = [
  /*'localhost:3000',*/
  "b2bfull.com.br",
  "b2bfull.vercel.app",
  "b2bfull-juanfariasdev.vercel.app",
  "www.b2bfull.com.br",
  "b2bfull.com.br",
  "ckeckout.b2bfull.com.br",
  "b2bfull-git-master-juanfariasdev.vercel.app",
];

export const DefaultTheme = {
  Title: "B2BFull",
  MetaTitle: "Prospectar clientes com eficiência!",
  favicon: "/favicon.ico",
  Logo: logoB2B,
  LogoBlack: LogoBlack,
  urls: B2bfullUrl,
  Text: {
    Login: {
      Image: FundoB2B,
      TitleLogin: "Prático, funcional e full.",
      subTitle: "Tudo em um só lugar!",
      Welcome: "Seja bem-vindo ao B2B Full",
      RegisterLink: "https://page.b2bfull.com.br/",
      WhatsAppNumber: "35988319388",
      WhatsappText: "Gostaria de saber mais sobre a B2BFull",
    },
    Dashboard: {
      Welcome: "Seja Bem-vindo,",
      WelcomeText:
        "O sistema ainda está em desenvolvimento e atualizações constante. Caso tenha alguma sugestão, entre em contato com o suporte!",
    },
  },
  Colors: {
    Primary: "#E76B3A",
    Secondary: "#E76B3A",
    Button: "#33B557",
  },
};
