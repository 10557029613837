import { useState } from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";

/* Icons for iconify */

import EyeOpenPassword from "../../../../image/EyeOpen.png";
import EyeClosedPassword from "../../../../image/EyeClosed.png";

/* Styles for MUI */
import {
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

import { useAuth } from "../../../../config/Hooks/userAuth";

import "./style.scss";
import { useThemeTemplate } from "../../../../config/Contexts/Theme";
import { verifyUserLogin } from "../../../../config/Hooks/verifyLogin";
// ----------------------------------------------------------------------

/* Functions */
export default function LoginForm() {
  const history = useHistory();
  const { user, SignIN } = useAuth();
  let verify = verifyUserLogin();
  const [showPassword, setShowPassword] = useState(false);
  const { Primary } = useThemeTemplate().theme.Colors;

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Coloque um e-mail válido!")
      .required("Preencha com seu e-mail"),
    password: Yup.string().required("Preencha com sua senha"),
  });

  async function handleLogin(response: any) {
    try {
      await SignIN(response).then(() => {
        history.push("/dashboard");
      });
    } catch (err) {
      alert(err);
    }
  }

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: true,
    },

    validationSchema: LoginSchema,

    onSubmit: async (response) => {
      await handleLogin(response);
    },
  });

  let { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  if (!verify) {
    return (
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              className="Input-Customization"
              fullWidth
              autoComplete="username"
              type="email"
              label="Endereço de e-mail"
              {...getFieldProps("email")}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <TextField
              className="Input-Customization"
              fullWidth
              autoComplete="current-password"
              type={showPassword ? "text" : "password"}
              label="Senha"
              {...getFieldProps("password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      {showPassword ? (
                        <img
                          className="ButtonEyePassword"
                          src={EyeClosedPassword}
                          alt="Olho com risco"
                        />
                      ) : (
                        <img
                          className="ButtonEyePassword"
                          src={EyeOpenPassword}
                          alt="olho aberto"
                        />
                      )}
                      {/* <Icon icon={showPassword ? EyeOpenPassword : eyeFill} /> */}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ my: 2 }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  {...getFieldProps("remember")}
                  checked={values.remember}
                  className="checkboxRemember"
                  style={{ color: "white" }}
                />
              }
              label={<span className="RememberPassword">Lembrar Senha</span>}
            />

            {/*<Link component={RouterLink} variant="subtitle2" to="#" className="linkRemember">
              Esqueceu a senha?
            </Link>*/}
          </Stack>

          <LoadingButton
            className="button buttonLogin"
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Entrar
          </LoadingButton>
        </Form>
      </FormikProvider>
    );
  } else {
    return (
      <LoadingButton
        className="button buttonLogin"
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={() => {
          history.push("/dashboard");
        }}
      >
        Acessar plataforma!
      </LoadingButton>
    );
  }
}
