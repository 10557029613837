
import { useState, useEffect } from 'react';
import axios from 'axios';


/* Styles for MUI */
import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid

} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/system';


/* Components */
import TableCNPJ from './components/Table/';
import InputList from './components/Filtros/InputList';
import Cidade from './components/Filtros/cidadeList';
import Cnae from './components/Filtros/cnaeList';


/* DataBase */
import { Database } from '../../../../../config/Database/database';


/* Styles */
import './style.scss';
import { api } from '../../../../../services/apiClient';


type Consulta = [{
  nomeFantasiaCNPJ: string,
  razao_social: string,
  cnae_fiscal: string,
  municipio: string,
  uf: string,
  porte: string,
}]


/* Functions */
export function Consultar() {
  const [data, setData] = useState<any>([]);
  const [form, setForm] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);

  let verificarTabela = JSON.parse(localStorage.getItem("tableSearch") as any);


  const UrlConsulta = Database();
  const baseURL = UrlConsulta + "/consulta/cnpj/";

  const baseURLConsulta = UrlConsulta + "/consulta/";
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';


  async function searchGet(resp: any) {
    localStorage.setItem("PaginationChange", "0")

    const consultar: Consulta = [{
      nomeFantasiaCNPJ: resp.txtName ? resp.txtName : "",
      razao_social: resp.txtRazao ? resp.txtRazao : "",
      cnae_fiscal: resp.Cname ? resp.Cname : "",
      municipio: resp.txtCidade ? resp.txtCidade : "",
      uf: resp.txtEstado ? resp.txtEstado : "",
      porte: resp.txtPorte ? resp.txtPorte : ""

    }
    ]

    const consultaFormat = consultar[0];

    setLoading(true)
    await axios.get(baseURLConsulta, { params: consultaFormat })
      .then(response => {
        setData(response.data);
        localStorage.setItem("tableSearch", JSON.stringify(response.data as any));
        setLoading(false);
      }).catch(() => {
        setLoading(false)
      });
  }
  useEffect(() => {
    const dataGet = async () => {
      let contarTabela = Object.keys(verificarTabela as any).length;

      if (contarTabela === 0) {
        setLoading(true)
        localStorage.setItem("PaginationChange", "0")

        await axios.get(baseURL)
          .then(response => {
            setData(response.data);
            localStorage.setItem("tableSearch", JSON.stringify(response.data as any));
            localStorage.setItem("PaginationChange", "0");
            setLoading(false)
          }).catch(() => {
            setLoading(false)
          });
      }
      else {
        setData(verificarTabela as any);
      }
    }
    dataGet();

  }, [])


  const updateField = (e: any) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,

    });
  };

  const handleSubmit = async () => {
    let Consulta = await form;
    setLoading(true)
    await api.get('/users/me').then(() => {
      searchGet(Consulta);
    }).catch((err) => {
      alert('Seu usuário foi deslogado, faça login novamente');
      window.location.href = "/";
    })
    
  }



  return (
    <div className="consultarcnpj">
      <div className="blockTop">
        <h2>Consultar CNPJ</h2>
        <FormControl className="filtros" >
          <Box sx={{ flexGrow: 1 }} className="cnpj">
            <Grid container xs={12} spacing={1}>
              <Grid item xs={12} sm={6} md={6} spacing={2}>
                <TextField sx={{ width: '100%', padding: 0 }} name="txtName" label="Nome fantasia ou CNPJ" variant="outlined" inputProps={{ autoComplete: "disabled" }}
                  onChange={updateField} autoComplete="off" />
                <TextField sx={{ width: '100%', padding: 0 }} name="txtRazao" label="Razão Social" variant="outlined" inputProps={{ autoComplete: "disabled" }}
                  onChange={updateField} autoComplete="off" />
              </Grid>
              {/* <TextField name="txtEstado" label="Estado" variant="outlined" onChange={updateField} /> */}

              <Grid item  xs={12} sm={3} md={3}>
                <InputList name="txtEstado" Namelabel="Estado" onChange={updateField} setForm={setForm} form={form} />

                <Cidade name="txtCidade" Namelabel="Cidade" onChange={updateField} setForm={setForm} form={form} />
              </Grid>


              {/*<TextField name="txtCidade" label="Cidade" variant="outlined" onChange={updateField} /> <TextField name="txtPorte" label="Porte da empresa" variant="outlined" onChange={updateField}/>*/}
              <Grid item xs={12} sm={3} md={3}>
                <Cnae name="Cname" Namelabel="CNAE" onChange={updateField} setForm={setForm} form={form} />
                <div>
                  <FormControl sx={{ m: 1, width: '100%' }}>
                    <InputLabel id="demo-simple-select-autowidth-label">Porte da empresa</InputLabel>
                    <Select
                      labelId="demo-simple-select-autowidth-label"
                      id="demo-simple-select-autowidth"
                      name="txtPorte"
                      onChange={updateField}
                      variant="outlined"
                      label="Porte da empresa"

                    >
                      <MenuItem value="">
                        <em>Nenhum</em>
                      </MenuItem>
                      <MenuItem value={1}>ME - Micro empresa</MenuItem>
                      <MenuItem value={3}>EPP - Empresa de Pequeno Porte</MenuItem>
                      <MenuItem value={5}>Demais</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </Grid>
            </Grid>

            <Grid container justifyContent="flex-end" margin="0">
              <LoadingButton
                className="button"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={handleSubmit}
              >
                Pesquisar
              </LoadingButton>
            </Grid>
          </Box>

        </FormControl>
      </div>

      <div className="blockTable">
        <TableCNPJ data={data} loading={loading} />
      </div>

    </div>
  )
}