import logoBinario from "../../image/binario/binario.png";
import capaBinario from "../../image/binario/capaBinario.png";
import BinarioFavicon from "../../image/binario/BinarioFavicon.png";

const BinarioUrl = ["binario.com.br"];

const Binario = {
  Title: "Binário",
  MetaTitle: "Soluções em tecnologia",
  favicon: BinarioFavicon,
  Logo: logoBinario,
  LogoBlack: logoBinario,
  urls: BinarioUrl,
  Text: {
    Login: {
      Image: capaBinario,
      TitleLogin: "Prático, funcional e full.",
      subTitle: "Tudo em um só lugar!",
      Welcome: "Bem vindo ao Binário System",
      RegisterLink: "https://page.b2bfull.com.br/",
      WhatsAppNumber: "35988319388",
      WhatsappText: "Gostaria de saber mais sobre o Binário System",
    },
    Dashboard: {
      Welcome: "Seja Bem-vindo,",
      WelcomeText:
        "O sistema ainda está em desenvolvimento e atualizações constante. Caso tenha alguma sugestão, entre em contato com o suporte!",
    },
  },
  Colors: {
    Primary: "#042451",
    Secondary: "#042451",
    Button: "#33B557",
  },
};

export const Theme_Definition = [Binario];
