import { useState } from 'react';

import axios from 'axios';

/* Hooks */
import { useAuth } from '../../../../../config/Hooks/userAuth';


/* Components */
import TableCNPJ from '../CNPJ/components/Table';
import ListImport from './components/listImport';

/* DataBase */
import { Database } from '../../../../../config/Database/database';

/* Styles */
import {
    Paper,
    Grid,
    TextField,
    Button
} from '@mui/material';

/* Functions */
export function ConsultarLista() {
    const [loading, setLoading] = useState<boolean>(false);
    let verificarConsultarLista = JSON.parse(localStorage.getItem("ConsultarLista") as any);
    const [dataTable, setData] = useState<any>(verificarConsultarLista ? verificarConsultarLista : []);
    const [dataImport, setImport] = useState<any>([]);

    const UrlConsulta = Database();
    const baseURL = UrlConsulta + "/consulta/";

    const getExention = (file: any) => {
        const EXTENSIONS = ['xlsx', 'xls', 'csv']
        const parts = file.name.split('.')
        const extension = parts[parts.length - 1]
        return EXTENSIONS.includes(extension) // return boolean
    }



    const convertToJSON = (Headers: any, data: any) => {
        const rows: any = []

        data.forEach((row: any) => {
            let rowData: any = {}

            row.forEach((element: any, index: any) => {
                rowData[Headers[index]] = element
            });
            rows.push(rowData)
        });
        return rows;
    }

    async function consultarDB(valor: any) {

        if (valor) {


            setLoading(true);
            let resultDB: any = [];
            let j = 0;
            setData([]);
            for (var i = 0; i < valor.length; i++) {
                setLoading(true)
                await axios.get(baseURL, { params: { nomeFantasiaCNPJ: valor[i] } })
                    .then(response => {
                        let convert: any = response.data;

                        if (convert[0]) {

                            resultDB[j] = convert[0];
                            j++;
                        }
                        //setData(dbTable);

                    }).catch(() => {
                        setLoading(false)
                    });
            }
            setLoading(false);
            localStorage.setItem("ConsultarLista", JSON.stringify(resultDB as any));
            setData(resultDB);
        }
        else {
            setData([]);
        }

    }




    const GerarTabela = (ArrayTable: any) => {
        let table = ArrayTable;

        let rows = [];
        consultarDB(table);

        rows.push(table)
    }


    const importTable = (e: any) => {
        const table = dataImport;

        let rowData: any = {}
        let importTeste: any = []

        table.map((lista: any, key: any) => {
            let filter = lista.CNPJ ? lista.CNPJ : lista.cnpj;

            if (filter) {
                let cnpj = filter!.toString().replace(/[^0-9]/g, '').substr(0, 8);
                importTeste[key] = cnpj
                return rowData[key] = cnpj
            }
        })
        let VerificaTamanho = importTeste.length;
        if (VerificaTamanho === 0) {
            alert("verifique se a tabela possui CNPJ");

        } else {
            GerarTabela(importTeste);
        }
    }


    const importExcel = async (e: any) => {
        const file = e.target.files[0]

        const reader = new FileReader()

        if (file) {
            if (getExention(file)) {
                const XLSX = await import ('xlsx')
                reader.onload = (event) => {
                    //parse data

                    const bstr = event.target?.result
                    const workBook = XLSX.read(bstr, { type: "binary" })
                    //get first sheet
                    const workSheetName = workBook.SheetNames[0]
                    const workSheet = workBook.Sheets[workSheetName]
                    const fileData = XLSX.utils.sheet_to_json(workSheet, { header: 1, defval: "", blankrows: false })
                    let Headers: any = [{}]
                    Headers = fileData[0]
                    const heads = Headers.map((head: any) => ({ title: head, field: head }))
                    

                    fileData.splice(0, 1)
                    setImport(convertToJSON(Headers, fileData))


                }


                reader.readAsBinaryString(file)
            }
            else {
                alert("Tipo de arquivo inválido, Use um arquivo XLSX ou CSV")
            }
        }




    }

    return (
        <div>
            <Paper sx={{ flexGrow: 1, marginBottom: 1, borderBottom: 1 + "px solid #cdcdcd", paddingTop: 2, paddingBottom: 2, boxShadow: "0px 4px 5px -1px rgb(0 0 0 / 20%)" }} elevation={0} className="socio-list-group" style={{ backgroundColor: "#fff" }}>
                <Grid container>
                    <Grid item xs={12} container sx={{ justifyContent: "left", alignItems: "flex-start", marginLeft: "20px", flexDirection: "column" }}>
                        <h2>Importar Lista</h2>
                        <p> - Importe seus leads para dentro da plataforma no formato de <b>CSV</b>.</p>
                        <p> - Dentro do <b>CSV</b> deve possuir um campo chamado <b>CNPJ</b>.</p>
                    </Grid>
                </Grid>
            </Paper>

            <Paper sx={{ flexGrow: 1, marginBottom: 1, borderBottom: 1 + "px solid #cdcdcd", padding: 2, boxShadow: "0px 4px 5px -1px rgb(0 0 0 / 20%)", justifyContent: 'space-between', display: "flex" }} elevation={0} className="socio-list-group" style={{ backgroundColor: "#fff" }}>
                <TextField
                    name="upload-list"
                    type="file"
                    onChange={importExcel}
                />
                <Button variant="contained" size="large"
                    onClick={importTable}
                    disabled={dataImport.length == 0 ? true : false}
                >
                    Importar Lista
                </Button>

            </Paper>


            {
                dataImport.length !== 0 ? (

                    <ListImport data={dataImport} />
                ) : ("")
            }

            <TableCNPJ export={true} data={dataTable} loading={loading} />
        </div >
    )
}