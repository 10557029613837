import { IVersion } from "../../../../../../config/Geral/interfaces/version"


interface IProps {
    data: IVersion[]
}

export function ListChange({data}: IProps){

    return(
        <>
            {data.map((val, key) => {

            return (
                <div className="Popup-Changelog" key={key}>
                    <h2><b>V{val.versao}</b> ({val.data})</h2>
                    <ul className="ListadeInformacao">
                        {val.GeralModificado?.map((valGeral, keyGeral) => {
                            return (
                                <li key={keyGeral}><b>{valGeral.title}</b> {valGeral.subtitle}</li>
                            )
                        })}
                    </ul>
                    <div className="RecursoEspecifico">

                        <ul className="ListadeInformacao">
                            {val.ItemModificado?.map((valMod, keyMod) => {
                                return (
                                    <>
                                        <h3 key={keyMod}><b>{valMod.title}</b></h3>
                                        <ul>
                                            {valMod.Modificacaoes?.map((valModFi, keyModFi) => {
                                                return (
                                                    <li key={keyModFi}><b>{valModFi.title}</b> {valModFi.subtitle}</li>
                                                )
                                            })}

                                        </ul>
                                    </>
                                )
                            })}

                        </ul>
                    </div>
                    <div className="CampoExtra" >
                        {val.extra?.map((valExtra, keyExtra) => {
                            return (

                                <p key={keyExtra}><b>{valExtra.title} </b>{valExtra.subtitle}</p>
                            )
                        })}
                    </div>
                </div>)
        })}
        </>
    )
}