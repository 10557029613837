import { useState, useEffect } from "react";
import axios from "axios";

/* Hooks */
import { useAuth } from "../../../../../config/Hooks/userAuth";

/* DataBase */
import { Database } from "../../../../../config/Database/database";

/* Components */

/* Styles */
import { Paper, Grid, CircularProgress } from "@mui/material";
import { SyncMinhaLista } from "./syncIndex";
import { AsyncMinhaLista } from "./asyncIndex";
import { parseCookies } from "nookies";

//const {user} = useAuth();
/* Functions */
export function MinhaLista() {
  const [dataTableRow, setDataRow] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const UrlConsulta = Database();

  async function consultarLista() {
    const { "b2bAuth.user": user } = parseCookies();
    let userIdLocal = user;
    let listaURL = UrlConsulta + "/user/list/";

    let ListaNoFormated: any = [];

    if (userIdLocal) {
      console.log("consultou");
      let value = await axios
        .get(listaURL, { params: { userID: userIdLocal } })
        .then(async (response) => {
          ListaNoFormated = response.data;
          return ListaNoFormated;
        });
      setLoading(false);
      return value;
    }
  }

  useEffect(() => {
    async function consultar() {
      let response = await consultarLista();
      console.log(response);
      setDataRow(response);
    }
    consultar();
  }, []);
  let forms = null;

  if(!loading){
    forms = dataTableRow.length < 150 ? (<SyncMinhaLista />) : (<AsyncMinhaLista data={dataTableRow} />)
  }

  return (
    <div>
      <Paper
        sx={{
          flexGrow: 1,
          marginBottom: 1,
          borderBottom: 1 + "px solid #cdcdcd",
          paddingTop: 2,
          paddingBottom: 2,
          boxShadow: "0px 4px 5px -1px rgb(0 0 0 / 20%)",
        }}
        elevation={0}
        className="socio-list-group"
        style={{ backgroundColor: "#fff" }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            container
            sx={{
              justifyContent: "left",
              alignItems: "flex-start",
              marginLeft: "20px",
              flexDirection: "column",
            }}
          >
            <h2>Minha Lista</h2>
            <p>
              - Lista criada a partir do botão <b>"ADICIONAR A LISTA"</b> que se
              encontra dentro de cada empresa.
            </p>
          </Grid>
        </Grid>
      </Paper>




      {!loading ? forms : (
        <Paper
          sx={{
            flexGrow: 1,
            marginBottom: 1,
            borderBottom: 1 + "px solid #cdcdcd",
            paddingTop: 2,
            paddingBottom: 2,
            boxShadow: "0px 4px 5px -1px rgb(0 0 0 / 20%)",
          }}
          elevation={0}
          className="socio-list-group"
          style={{ backgroundColor: "#fff" }}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              container
              sx={{
                justifyContent: "left",
                alignItems: "center",
                marginLeft: "20px",
                flexDirection: "column",
              }}
            >
              <h2>Carregando dados</h2>
              <CircularProgress size={40} />
            </Grid>
          </Grid>
        </Paper>
      )}
    </div>
  );
}
