import { IAuthenticate, IPages, IProps, IUsers } from "./IServerProps";

const Authenticate: IAuthenticate = {
  sessions: "/sessions",
  refreshToken: "/refresh-token",
};

const Users: IUsers = {
  url: "/users",
  profile: "/me",
  avatar: "/avatar",
};

const pages: IPages = {
  auth: Authenticate,
  users: Users,
};

const serverConfig: IProps = {
  url: "https://b2bfull-users.onrender.com",
  pages: pages,
};

const ServerAPI: IProps = {
  url: serverConfig.url,
  pages: {
    auth: {
      sessions: serverConfig.pages.auth.sessions,
      refreshToken: serverConfig.pages.auth.refreshToken,
    },
    users: {
      url: serverConfig.pages.users.url,
      profile:
        serverConfig.pages.users.profile + serverConfig.pages.users.profile,
      avatar: serverConfig.pages.users.url + serverConfig.pages.users.avatar,
    },
  },
};

export { ServerAPI };
