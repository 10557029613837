/***************************************/
/* COLOQUE SEMPRE UM EM BAIXO DO OUTRO */
/***************************************/

import { IVersion } from "./interfaces/version";


export const VersaoSistema: IVersion[] = [
  {
    versao: "1.0.0.0",
    data: "03/11/2021",
    GeralModificado: [
      {
        title: "implementação",
        subtitle: "Foi implementado o changelog",
      },
    ],
    active: true,
    ItemModificado: [
      {
        title: "Dashboard Inicio",
        Modificacaoes: [
          {
            title: "Adicionado",
            subtitle: "Changelog",
          },
        ],
      },
    ],
    extra: [
      {
        title: "AVISO",
        subtitle:
          "Tudo que foi feito anterior a data de implementação não será listado aqui",
      },
    ],
  },
  {
    versao: "1.0.0.1",
    data: "03/11/2021",
    GeralModificado: [
      {
        title: "Melhorias ",
        subtitle: "correção e automação da Versão",
      },
    ],
  },
  {
    versao: "1.0.0.2",
    data: "03/11/2021",
    GeralModificado: [
      {
        title: "Modificação ",
        subtitle: "botão de ação modificado para abrir em outra página",
      },
    ],
  },
  {
    versao: "1.0.0.3",
    data: "10/11/2021",
    GeralModificado: [
      {
        title: "Melhorias ",
        subtitle: "Melhorias de Velocidade na consulta ao Municipio",
      },
    ],
  },
  {
    versao: "1.0.1.0",
    data: "10/11/2021",
    GeralModificado: [
      {
        title: "implementação",
        subtitle: "Foi implementado o menu lateral",
      },
    ],
    active: true,
    ItemModificado: [
      {
        title: "Menu Lateral",
        Modificacaoes: [
          {
            title: "Adicionado",
            subtitle: "Menu lateral responsivo",
          },
        ],
      },
    ],
  },
  {
    versao: "1.0.3.0",
    data: "11/11/2021 - 12/11/2021",
    GeralModificado: [
      {
        title: "Melhorias",
        subtitle: "O layout foi reformulado ",
      },
    ],
    active: true,
  },
  {
    versao: "1.1.0.0",
    data: "12/11/2021 - 19/11/2021",
    GeralModificado: [
      {
        title: "Atualização",
        subtitle: "Banco de Dados Atualizado",
      },
    ],
    active: true,
    ItemModificado: [
      {
        title: "Tabela",
        Modificacaoes: [
          {
            title: "Adicionado",
            subtitle: "Mais campos e mais opções",
          },
        ],
      },
    ],
  },
  {
    versao: "1.1.1.0",
    data: "22/11/2021",
    GeralModificado: [
      {
        title: "Implementação",
        subtitle: "Sistema de limpeza de CACHE implementado",
      },
    ],
  },
  {
    versao: "1.2.0.0",
    data: "07/12/2021",
    GeralModificado: [
      {
        title: "Implementação",
        subtitle: "Minha lista (Versão BETA)",
      },
      {
        title: "Melhorias",
        subtitle: "Performance na consulta que envolve cidade",
      },
      {
        title: "Correção de bug mobile",
        subtitle: "Apresentação do menu lateral no IOS",
      },
    ],
  },
  {
    versao: "1.2.1.0",
    data: "08/12/2021",
    GeralModificado: [
      {
        title: "Correção",
        subtitle: "O menu estava bugando no Windows",
      },
    ],
  },
  {
    versao: "1.3.1.0",
    data: "1/12/2021",
    GeralModificado: [
      {
        title: "Adicionado",
        subtitle: "Minha Lista",
      },
    ],
  },
  {
    versao: "1.3.2.0",
    data: "17/12/2021",
    GeralModificado: [
      {
        title: "Mehlorias",
        subtitle: "Melhorias em cache e visual",
      },
    ],
  },
  {
    versao: "1.3.3.0",
    data: "25/01/2022",
    GeralModificado: [
      {
        title: "Melhorias",
        subtitle: "Melhorias da Tela de Login",
      },
    ],
  },
  {
    versao: "1.3.4.0",
    data: "25/01/2022",
    GeralModificado: [
      {
        title: "Melhorias",
        subtitle: "Melhorias no Whatsapp",
      },
    ],
  },
  {
    versao: "1.3.5.0",
    data: "25/01/2022",
    GeralModificado: [
      {
        title: "Melhorias",
        subtitle: "Melhorias do código",
      },
    ],
  },
  {
    versao: "1.3.6.0",
    data: "25/01/2022",
    GeralModificado: [
      {
        title: "Correção de bugs",
        subtitle: "Em navegadores antigos dava problema no visualizar",
      },
    ],
  },
  {
    versao: "1.3.7.0",
    data: "26/01/2022",
    GeralModificado: [
      {
        title: "Correção de bugs",
        subtitle: "Chrome antigo era imcompativel",
      },
    ],
  },
  {
    versao: "1.4.0.0",
    data: "10/02/2022",
    GeralModificado: [
      {
        title: "Implementação de multi temas",
        subtitle: "Agora é possivel ter em várias empresas ao mesmo tempo",
      },
    ],
  },
  {
    versao: "1.4.1.0",
    data: "17/03/2022",
    GeralModificado: [
      {
        title: "Botão de exportação exportação",
        subtitle: "Exportação adicionada para a lista",
      },
    ],
  },
  {
    versao: "1.4.2.0",
    data: "14/03/2022",
    GeralModificado: [
      {
        title: "implementação",
        subtitle: "Google Analitics",
      },
    ],
  },
  {
    versao: "1.5.0.1",
    data: "25/03/2022",
    GeralModificado: [
      {
        title: "Atualização",
        subtitle: "Banco de Dados Atualizado e melhorado",
      },
      {
        title: "Melhorias",
        subtitle: "cookies totalmente removido",
      },
    ],
  },

  {
    versao: "1.5.1.0",
    data: "28/03/2021",
    GeralModificado: [
      {
        title: "Correção",
        subtitle: "Usuário logado",
      },
    ],
  },
  {
    versao: "1.5.1.1",
    data: "31/03/2021",
    GeralModificado: [
      {
        title: "Melhorias",
        subtitle: "Maneira de adicionar a lista foi melhorada",
      },
    ],
  },
  {
    versao: "1.5.2.1",
    data: "01/04/2021",
    GeralModificado: [
      {
        title: "Correção",
        subtitle: "Bug da tela branca",
      },
      {
        title: "Correção",
        subtitle: "Rotas da aplicação",
      },
    ],
  },
  {
    versao: "1.6.3.2",
    data: "07/04/2021",
    GeralModificado: [
      {
        title: "Firebase foi removido",
        subtitle: "Agora o Sistema utiliza o Banco de dados independente",
      },
      {
        title: "implementação",
        subtitle: "CEP foi implementado",
      },
      {
        title: "implementação",
        subtitle: "Opção de remover da lista adicionada",
      },
      {
        title: "Correção",
        subtitle: "Bugs no 'minha lista'",
      },
      {
        title: "Correção",
        subtitle: "Remover aparecendo onde não deveria",
      },
      {
        title: "Correção",
        subtitle: "Limpeza de cache da aplicação",
      },
    ],
  },
  {
    versao: "1.6.4.0",
    data: "13/04/2021",
    GeralModificado: [
      {
        title: "implementação",
        subtitle: "Exportação da lista para administradores adicionado",
      },
    ],
  },
  {
    versao: "1.6.4.1",
    data: "28/04/2021",
    GeralModificado: [
      {
        title: "Correção",
        subtitle: "Capital social",
      },
    ],
  },
  {
    versao: "1.7.0.1",
    data: "29/04/2021",
    GeralModificado: [
      {
        title: "implementação",
        subtitle: "Funcionalidade de redes sociais dos sócios adicionada",
      },
      {
        title: "Melhorias",
        subtitle: "Empresas desativadas em vermelho removido temporariamente",
      },
    ],
  },
  {
    versao: "1.7.1.2",
    data: "02/05/2021",
    GeralModificado: [
      {
        title: "Correção e melhoria",
        subtitle: "Lista do usuário",
      },
      {
        title: "Correção",
        subtitle: " Exibição na consulta",
      },
      {
        title: "Melhorias",
        subtitle: "Empresas desativadas ficam em vermelho novamente",
      },
    ],
  },
  {
    versao: "1.7.3.0",
    data: "02/05/2021",
    GeralModificado: [
      {
        title: "Remoção de itens desnecessários",
        subtitle: "Consumo exagerado de recursos evitado",
      },
      {
        title: "Correção",
        subtitle: " Usuários sem lista",
      },
    ],
  },
];
