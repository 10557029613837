import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { IconButton } from '@mui/material';
import { forwardRef, ReactElement, ReactNode, Ref, useState } from 'react';

interface Irequest {
  children: ReactNode;
  Title: string;
  Menssage?: string;
  msgConfirm?: string;
  onClick: () => void;
}


const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function AlertDialogWarning({ children, Title = "Titulo", Menssage = "Mensagem", msgConfirm = "Confirmar", onClick: funcComponent }: Irequest) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        { children }
      </IconButton>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{ Title }</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            { Menssage }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={() => {
            funcComponent();
            handleClose()}}>{ msgConfirm }</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}