import { Link } from "react-router-dom";
import { ReactNode, useEffect, useState } from "react";

/* Components */
import { UserAside } from "./components/user";
import { Menu } from "./components/menu/";

/* Styles for MUI */
import { styled } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CachedIcon from "@mui/icons-material/Cached";

import {
  Tooltip,
  Box,
  Drawer,
  CssBaseline,
  Toolbar,
  IconButton,
} from "@mui/material";

import { MeetingRoom } from "@mui/icons-material";

/* Images */
import userNotFound from "../../image/userNotFound.jpg";

/* Hooks */
import { useAuth } from "../../config/Hooks/userAuth";
import cacheCookies from "../../config/Hooks/cacheCookies";

/* Styles */
import "./style.scss";
import "../../styles/global.scss";
import { useThemeTemplate } from "../../config/Contexts/Theme";
import useGaTracker from "./components/GATracker";

type AuthProviderProps = {
  children: ReactNode;
};

const drawerWidth = 300;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "space-between",
}));

/* Functions */
export function Dashboard(props: AuthProviderProps) {
  useGaTracker();
  const { LogoBlack } = useThemeTemplate().theme;
  const logout = useAuth();
  const { user } = useAuth();

  localStorage.getItem("menub2bfull") ??
    localStorage.setItem("menub2bfull", "true");

  const [open, setOpen] = useState(
    Boolean(localStorage.getItem("menub2bfull") === "true")
  );

  const handleDrawerMenu = () => {
    var value = !open;
    setOpen(value);
    localStorage.setItem("menub2bfull", value.toString());
  };

  useEffect(() => {
    cacheCookies();
  }, []);

  return (
    <Box
      sx={{ display: "flex" }}
      id="page-dashboard"
      className={open ? "OpenedMenu" : ""}
    >
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ backgroundColor: "white", color: "black", zIndex: 2000 }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerMenu}
            edge="start"
            sx={{ mr: 3 }}
          >
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            <MenuIcon />
          </IconButton>
          <Link
            to="/dashboard"
            style={{
              justifyContent: "center",
              display: "flex",
              left: "50%",
              right: "50%",
              position: "absolute",
            }}
          >
            <img
              src={LogoBlack}
              alt={"Logo " + LogoBlack}
              style={{ maxWidth: "130px" }}
            />
          </Link>
          <div>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => {
                logout.CleanCache();
              }}
              edge="start"
              sx={{ mr: 3 }}
            >
              <Tooltip title="Limpar Cache">
                <CachedIcon />
              </Tooltip>
            </IconButton>

            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => {
                logout.signOut();
              }}
              edge="start"
              sx={{ mr: 3 }}
            >
              <Tooltip title="Sair">
                <MeetingRoom />
              </Tooltip>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <UserAside
          img={user?.avatar ? user.avatar : userNotFound}
          name={user?.name}
          cargo="Desenvolvedor"
          email={user?.email}
          validate={user?.signature_end}
        />
        <Menu />
      </Drawer>
      <Main open={window.screen.availWidth > 780 ? open : false}>
        <DrawerHeader />
        {props.children}
      </Main>
    </Box>
  );
}
