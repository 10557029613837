import { IBoardData } from "../../../hooks/Context"

export function setPriority(id: string | number, priority: number, newBoard: any, setNewBoard: any) {
    let prior = priority

    let priorityFinal = JSON.parse(JSON.stringify(newBoard))
    priorityFinal.forEach((board: IBoardData) => board.items.forEach((items: any) => {
      if (items.id === id) {
        items.priority = prior
      }
    }))
    setNewBoard(priorityFinal)
  }