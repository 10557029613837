import Routes from "./config/Routes/";



const App = () => {

    return <Routes />
};

export default App;
