import axios from "axios";
import { parseCookies } from "nookies";
import { useEffect, useState } from "react";
import { Database } from "../../../../../../config/Database/database";
import { api } from "./apiClient";
import { IBoardData } from "./Context";

async function listTablesKanban() {
    const UrlConsulta = Database();
    const baseURL = UrlConsulta + "/consulta/";

    async function consultarDB(valor: any) {
        let responseDB: any = {};
        if (valor) {
            await axios.get(baseURL, { params: { nomeFantasiaCNPJ: valor } })
                .then(response => {
                    responseDB = response.data[0];
                });
        }
        return responseDB;
    }

    let tables: IBoardData[] = await api.get("kanban/table").then(async (response) => {
        let value = response.data;

        function sort_position(a: any, b: any) {
            return a.position - b.position;
        }

        await Promise.all(value.map(async (table: IBoardData) => {
            if (table.name === "Temporário") {
                table.position = 0;
            }
            await Promise.all(table.items.map(async (item) => {
                let itemComplete = await consultarDB(item.cnpj);

                if (!!itemComplete) {
                    let title = itemComplete.nome_fantasia ? itemComplete.nome_fantasia : itemComplete.razao_social;
                    let porte = itemComplete.porte_empresa ? itemComplete.porte_empresa : "";
                    item.title = title ? title : "";
                    item.porte = porte;

                    console.log(itemComplete);
                }
            }))
            table.items.sort(sort_position);
        }))

        value.sort(sort_position);

        return value;
    }).catch(() => { return [] })

    return tables;
}

async function saveBoardAction(table: IBoardData[]) {
    const response = await api.put("kanban/tables", { tables: table }).then((resp) => {
        return resp;
    }).catch((err) => { throw new Error(err.message) })

    return response;
}


export { listTablesKanban, saveBoardAction }

