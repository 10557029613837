import { CircularProgress, Grid, Paper, SvgIcon } from "@mui/material";
import Construct from "../../image/construct.svg";
export function Manutencao() {
    return (
        <Paper sx={{flexGrow: 1, marginBottom: 1, borderBottom: 1 + "px solid #cdcdcd", paddingTop: 5, paddingBottom: 5, paddingLeft: 2, paddingRight: 2, boxShadow: "0px 4px 5px -1px rgb(0 0 0 / 20%)" }} elevation={0} className="socio-list-group" style={{ backgroundColor: "#fff" }}>
        <Grid container>
            <Grid item xs={12} container sx={{ justifyContent: "left", alignItems: "center", flexDirection: "column" }}>

                <h1 style={{marginBottom: "20px"}}>Em construção...</h1>
               
                <img src={Construct} style={{ maxWidth: "100%"}}/>
            </Grid>
        </Grid>
 
    </Paper>
    )
}