//import '../../styles/Dashboard/main/welcome.scss';

/* Hooks */
import { useAuth } from "../../../../config/Hooks/userAuth";

/* Components */
import ChangeLog from "./components/changelog";

/* Styles */
import "../../style.scss";
import "./style.scss";
import { useThemeTemplate } from "../../../../config/Contexts/Theme";

/* Images */
import BannerInitialDesktop from "../../../../image/banner-inicio.png";
import BannerInitialMobile from "../../../../image/banner-inicio-mobile.png";

/* Functions */
export function DashboardInicio() {
  const { user } = useAuth();
  const { Welcome, WelcomeText } = useThemeTemplate().theme.Text.Dashboard;
  const { Primary } = useThemeTemplate().theme.Colors;
  return (
    <div className="blockWelcome">
      <div className="blockTop Welcome" style={{ background: Primary }}>
        <div className="text--Welcome">
          <h2>
            {Welcome}
            <br /> {user?.name ? user.name : "Carregando..."}
          </h2>
          <br />
          <p>{WelcomeText}</p>
        </div>
      </div>
      <div>
        <ChangeLog />
      </div>
      <div className="AreaBannersInitialPage">
        <a
          target={"_blank"}
          href="https://api.whatsapp.com/send?phone=5535988319388&text=Gostaria%20de%20falar%20com%20um%20especialista%20da%20B2BFull"
          className="bannerInTheInitialPageDesktop"
        >
          <img src={BannerInitialDesktop} alt="" />
        </a>
        <a
          target={"_blank"}
          href="https://api.whatsapp.com/send?phone=5535988319388&text=Gostaria%20de%20falar%20com%20um%20especialista%20da%20B2BFull"
          className="bannerInTheInitialPageMobile"
        >
          <img src={BannerInitialMobile} alt="" />
        </a>
      </div>
    </div>
  );
}
