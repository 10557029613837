import SearchIcon from '@mui/icons-material/Search';
import StarsOutlinedIcon from '@mui/icons-material/StarsOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import HouseIcon from '@mui/icons-material/House';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
export const MenuData = [
    {
        title: "Home",
        icon: <HouseIcon />,
        link: "/dashboard",
        active: true,

    },
    {
        title: "CNPJ",
        icon: <SearchIcon />,
        link: "/dashboard/home",
        active: true,
        submenu: [
            {
                title: "Consultar CNPJ",
                icon: <FiberManualRecordIcon />,
                link: "/dashboard/cnpj/consultar",
                active: true,
            },
            {
                title: "Consultar Sócios",
                icon: <FiberManualRecordIcon />,
                link: "/dashboard/socio/consultar",
                active: false,
            },

            {
                title: "Importar Lista",
                icon: <FiberManualRecordIcon />,
                link: "/dashboard/cnpj/importarlista",
                active: true,
            },
            {
                title: "Minha Lista",
                icon: <FiberManualRecordIcon />,
                link: "/dashboard/cnpj/minhalista",
                active: true,
            },
            {
                title: "Meu CRM",
                icon: <FiberManualRecordIcon />,
                link: "/dashboard/cnpj/crm",
                active: true,
            },
        ],

    },
    {
        title: "Comunidade B2B",
        icon: <ImportContactsIcon />,
        link: "/dashboard/comunidade",
        active: true,

    },
    {
        title: "Solicite Afiliação",
        icon: <PeopleAltIcon />,
        class: "menuCustom",
        link: "/dashboard/afiliacao",
        active: true,

    },
    {
        title: "Admin",
        icon: <StarsOutlinedIcon />,
        link: "/dashboard/admin",
        active: false,

    },

];
