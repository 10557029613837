
import { createContext, ReactNode, useContext } from "react";


import { ThemeProps, ThemeTemplateContextType } from "../../config/Theme/ThemeType";
import { Theme_Definition } from "../../config/Theme/";
import { DefaultTheme } from "../../config/Theme/default";

interface ThemeTemplateProviderProps {
    children: ReactNode;
}


const ThemeTemplateContext = createContext({} as ThemeTemplateContextType);



export function ThemeTemplateProvider({ children }: ThemeTemplateProviderProps) {
    function getFaviconEl() {
        return document.getElementById("favicon");
    }
    function getTitleEl() {
        return document.getElementById("title");
    }
    let Theme = Theme_Definition;


    const defineTheme: any = () => {
        let URL = "";
        let setTheme: ThemeProps = DefaultTheme;

        Theme.some((item, key) => {
            let verify = item.urls.some((url) => {
                if (window.location.host === url) {
                    URL = url
                    return url
                }
            });
            if (verify) {
                setTheme = item;
                return verify
            }


        });
        return setTheme

    }
    let favicon: any = getFaviconEl(); // Accessing favicon element
    let title: any = getTitleEl()

    if (favicon && title) {
        favicon.href = defineTheme().favicon
        title.innerHTML = (defineTheme().Title + " - " + defineTheme().MetaTitle)
    }
    const theme = defineTheme()


    return (
        <ThemeTemplateContext.Provider value={{ theme }}>
            {children}
        </ThemeTemplateContext.Provider>
    )
}


export const useThemeTemplate = () => useContext(ThemeTemplateContext);