import * as React from 'react';
import axios from 'axios';


/* Styles for MUI */
import {
  TextField,
  CircularProgress,
  Autocomplete
} from '@mui/material';


/* Database */
import { Database } from '../../../../../../../config/Database/database';


interface Film {
  id: string;
  label: string;
}
type Consulta = {
  uf?: string,
}


/* Functions */
export default function Cidade({ name, onChange, Namelabel, setForm, form }: any) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<readonly Film[]>([]);
  const loading = open && options.length === 0;

  let botaoAtivo = false;

  const UrlConsulta = Database();
  const baseURL = UrlConsulta + "/consulta/municipio/";

  function convert(resp: any) {

    const newArray = resp.map((item: any) => {
      return ({ id: item.cod_tom, label: item.nome });
    });

    setOptions(newArray);

  };

  const updateField = (Value: any) => {
    let ValueDef = Value;
    ValueDef = ValueDef.id ? ValueDef.id : null;

    setForm({
      ...form,
      [name]: Value.id,
    });

  };

  if (form.txtEstado === undefined) {
    botaoAtivo = true;
  }





  const consultar: Consulta = {
    uf: form.txtEstado ? form.txtEstado : "",
  };

  const consultaFormat = consultar;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      if (active) {
        await axios.get(baseURL, { params: consultaFormat })
          .then(response => {
            convert(response.data);


          }).catch(() => {
            setOptions([]);
          });
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      disabled={botaoAtivo}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      sx={{ width: '100%' }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(event: any, newValue) => {
        if (newValue) {
          updateField(newValue);
        }
        else {
          updateField('');
        }
      }}
      getOptionLabel={(option) => option.label}
      options={options}
      loading={loading}

      renderInput={(params) => (
        <TextField
          autoComplete="disabled"
          {...params}
          inputProps={{
            ...params.inputProps,
            autoComplete: "disabled", // disable autocomplete and autofill
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          label={Namelabel} variant="outlined"

        />
      )}
    />
  );
}
