import { v4 as uuidv4 } from 'uuid';
import { createContext, useContext, useEffect, useState } from 'react';
import { listTablesKanban, saveBoardAction } from './kanban';

export interface IBoardItem {
    id: number | string;
    user_id?: string;
    position?: number;
    priority?: number;
    title?: string;
    cnpj?: string;
    porte?: number;
    observation?: any[];
    info?: any[];
    created_at?: Date;
    updated_at?: Date;
    
}
  export interface IBoardData {
    id: number | string;
    name: string;
    position: number;
    items: IBoardItem[];
}

export function createId() {

  let id = uuidv4();
    //ID SEMPRE TEM QUE SER NUMEROS INTEIROS E GRANDES
    return id;

}

const Context = createContext<[IBoardData[], any, boolean]>(undefined!);


export function ContextWrap({ children }: any) {
  const [newBoard, setBoard] = useState<IBoardData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  function setNewBoard(data: IBoardData[]){
    if(data !== undefined){
      setBoard(data);
    }
  }
  useEffect(() => {
    async function getList(){
      let table = await listTablesKanban();

      if(table){
        setBoard(table);
        setLoading(false);
      }
  }
  getList()
  },[])

    return (
        <Context.Provider value={[newBoard, setNewBoard, loading]}>
            {children}
        </Context.Provider>
    );
}

export function useAppContext() {
  return useContext(Context);
}