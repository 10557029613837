
import { ContextWrap } from "./hooks/Context";

import './styles/layout.scss'
import './styles/boards.scss'
import './styles/cardItem.scss'
import './styles/modal.scss'
import { KanbanHome } from "./kanban";

export function CRM() {
  return (
    <ContextWrap>
        <KanbanHome/>
    </ContextWrap>
  )
}

