import { useEffect, useState } from 'react'
import CardItem from './CardItem'

import CheckIcon from '@mui/icons-material/Check';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { Menu } from '@szhsin/react-menu'

import { IBoardData, IBoardItem, useAppContext } from '../hooks/Context';

import { editFunction } from './utils/Boards/editFunction';
import { editFunctionClick } from './utils/Boards/editFunctionClick';
import { deleteBoard } from './utils/Boards/deleteBoard';
import { Button, IconButton, MenuItem, TextField, Tooltip } from '@mui/material';

import '@szhsin/react-menu/dist/index.css'
import '@szhsin/react-menu/dist/transitions/slide.css';

interface Iresquest {
  data?:  IBoardData[]
}

function Boards({ data }: Iresquest) {

  const [newBoard, setNewBoard, loading] = useAppContext()
  const [selectedBoard, setSelectedBoard] = useState<string | number>(0)
  const [editBoard, setEditBoard] = useState(false)
  const [editCard, setEditCard] = useState(false)
  const [ready, setReady] = useState(false)

  useEffect(() => {
    setReady(true)
  }, [])

  const onDragEnd = (re: any) => {
    if (!re.destination) return
    let newBoardData:IBoardData[] = JSON.parse(JSON.stringify(newBoard));

    var dragItem = newBoardData[parseInt(re.source.droppableId)].items[re.source.index];
    newBoardData[parseInt(re.source.droppableId)].items.splice(re.source.index,1)

    newBoardData[parseInt(re.destination.droppableId)].items.splice(re.destination.index, 0, dragItem)
    newBoardData.map((table, key) => {
      table.position = key;
      table.items.map((item, key) => {
        return item.position = key
      })
    })
    setNewBoard(newBoardData)
  }

  return (
      <div>
        {ready && (
          <DragDropContext onDragEnd={onDragEnd}>
            <div className="BoardCollumGeneral">
              {newBoard?.map((board: IBoardData, key: number) => {
                let id = board.id;
               
                return (
                  <div key={id}>
                    <Droppable droppableId={key.toString()}>
                      {(provided, snapshot) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                          <div className={`CollumGeneralArea ${snapshot.isDraggingOver && 'GreenColor'}`}>
                            <span className="RedLineCollum">
                            </span>
                            <h4 className="TitleBoardArea">
                              <span className="BoardTitle">
                                {!!editBoard && selectedBoard === id ? (
                                  <div>
                                    <TextField id="boardEditForm" autoFocus defaultValue={board.name}
                                    data-id={id} rows={1} onKeyDown={async (e) => {
      
                                      let value = await editFunction(id, e, newBoard, setEditBoard)
                                      setNewBoard(value);

                                      }}></TextField>
                                    <Button onClick={async () => {
                                      let value = await editFunctionClick(id, newBoard, setEditBoard)
                                      setNewBoard(value)
                                      }}><CheckIcon/></Button>
                                  </div>
                                ) : (
                                  <span>{board.name}</span>
                                )}
                              </span>
                              <Menu menuButton={
                              <Tooltip title="Opções">
                                <IconButton aria-label="settings">
                                  <MoreVertIcon />
                                </IconButton>
                              </Tooltip>
                            } transition>
                                <MenuItem onClick={() => { setSelectedBoard(id); setEditBoard(true) }}>Editar</MenuItem>
                                <MenuItem onClick={async () => { 
                                  let value = await deleteBoard(id, newBoard) 
                                  setNewBoard(value)

                                  }}>Excluir</MenuItem>
                              </Menu>
                            </h4>
                            <div className="CardAreaInCollum"
                            style={{ maxHeight: 'calc(100vh - 290px)' }}>
                              {board.items?.length > 0 ?
                                board.items.map((item: IBoardItem, iIndex: any) => {
                                  return (
                                    <CardItem key={item.id} data={item} index={iIndex} />
                                  )
                                }) : <p className="EmptyBoard">Board vazio 😢</p>}
                              {provided.placeholder}
                            </div>

{/*}
                            {(editCard === true && selectedBoard === id) ? (
                              <div className="AroundTextAreaAddTask">
                                <TextField id="taskTitle" autoFocus placeholder="Descrição do Card"
                                data-id={id} onKeyDown={(e) => {
                                  let value = createCard(id, e, setEditCard, newBoard)

                                  setNewBoard(value);
                                  }}/>
                                <Button onClick={() => {setEditCard(false)}}>x</Button>
                              </div>
                            ) : (
                              <Button
                                className="ButtonAddTask"
                                onClick={() => { setSelectedBoard(id); setEditCard(true) }}>
                                <span>Adicionar Card</span>
                                <AddIcon className="PlusCircleIcon"/>
                              </Button>
                            )}*/}
                          </div>
                        </div>
                      )}
                    </Droppable>
                  </div>
                )
              })}
              {newBoard?.length === 0 && <span>Adicione uma tabela</span>}
              
            </div>
          </DragDropContext>
        )}
      </div>
  )
}

export default Boards
