import * as React from 'react';
import { Link } from 'react-router-dom';


/* Styles for MUI */
import {
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Collapse,
    Divider
}
    from '@mui/material';

import {
    ExpandLess,
    ExpandMore,
    MeetingRoom,
} from '@mui/icons-material';


/* Hooks */
import { useAuth } from '../../../../config/Hooks/userAuth';
import { MenuData } from '../../../../config/Geral/MenuData';


/* Styles */
import './style.scss';
import { useThemeTemplate } from '../../../../config/Contexts/Theme';


/* Functions */
export function Menu() {
    const [open, setOpen] = React.useState(true);
    const { Secondary } = useThemeTemplate().theme.Colors;
    const logout = useAuth();
    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <div className="MenuSidebar">
            <ul className="MenuSidebarList">
                {MenuData.map((val, key) => {
                    if (val.submenu && val.active) {
                        return (
                            <>
                                <ListItemButton onClick={handleClick} key={key}>
                                    <ListItemIcon>
                                        {/* item icone */}
                                        {val.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={val.title} />
                                    {open ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>

                                {
                                    val.submenu.map((subitem, subindex) => {
                                        let divStyle = {}
                                        if (subitem.active) {
                                            if (window.location.pathname === subitem.link) {
                                                divStyle = {
                                                    background: Secondary
                                                }
                                            }
                                            return (

                                                <Collapse in={open} timeout="auto" unmountOnExit key={subindex}>
                                                    <Link to={subitem.link} className="row linhasMenu" id={window.location.pathname === subitem.link ? "active" : ""} style={divStyle}>
                                                        <List component="div" disablePadding className="linhasMenuList">
                                                            <ListItemButton sx={{ pl: 2 }} className="BaseRowMenu">
                                                                <ListItemIcon className="icon iconsubmenu">

                                                                    {/* Subitem icone */}
                                                                    {subitem.icon}

                                                                </ListItemIcon>
                                                                <ListItemText primary={subitem.title} />
                                                            </ListItemButton>
                                                        </List>
                                                    </Link>
                                                </Collapse>



                                            )
                                        }
                                        else {
                                            return null;
                                        }
                                    })}
                                <Divider /></>

                        )
                    }
                    else {
                        if (val.active) {
                            let divStyle = {}

                            if (window.location.pathname === val.link) {
                                divStyle = {
                                    background: Secondary
                                };
                            }
                            return (
                                <Link to={val.link} className={"row" + " " + val.class} id={window.location.pathname === val.link ? "active" : ""}
                                    style={divStyle}>
                                    <ListItemButton key={key} className="menuprincipal">
                                        <ListItemIcon className="icon">
                                            {val.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={val.title} />
                                    </ListItemButton>
                                </Link>

                            )
                        }
                        else {
                            return null;
                        }

                    }
                }

                )}
                <Link className="row" to="#" onClick={() => {
                    logout.signOut();
                }}>
                    <ListItemButton className="menuprincipal">
                        <ListItemIcon className="icon">
                            <MeetingRoom />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                    </ListItemButton>
                </Link>
            </ul>
        </div>
    )
}
