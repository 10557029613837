import { Link } from "react-router-dom";
import Papa from "papaparse";
/* Styles for MUI */
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  useGridApiContext,
  GridCsvExportOptions,
} from "@mui/x-data-grid";

/* Importa a Data Grid */
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";

/* Importa o Material */
import {
  Tooltip,
  IconButton,
  ThemeProvider,
  createTheme,
  Button,
  ButtonProps,
} from "@mui/material";

/* Importa Icones da Material */

import {
  SaveAlt,
  VisibilityRounded,
  DeleteForeverRounded,
} from "@mui/icons-material";

/* Traduções */
import { ptBR } from "@mui/material/locale";
import { GridTraducao } from "../../../../../../../config/Geral/Traducao";
import { useAuth } from "../../../../../../../config/Hooks/userAuth";
import { Database } from "../../../../../../../config/Database/database";
import axios from "axios";
import { AlertDialogWarning } from "../../../../../../../components/AlertDialog";
import { useEffect, useState } from "react";
import { testModeAPI } from "react-ga";

var exportTools = false;

const theme = createTheme(
  {
    palette: {
      primary: { main: "#1976d2" },
    },
  },
  ptBR
);

const UrlConsulta = Database();
let userID = "";

let funcRemove = () => {};

function RemoveList(cnpj: string) {
  let URLRemove = UrlConsulta + "/user/lista/remove";

  const Remove = async () => {
    await axios
      .get(URLRemove, { params: { userID: userID, cnpj: cnpj } })
      .then((response) => {
        funcRemove();
      });
  };
  Remove();
}

/* Functions */
function CustomToolbar() {
  const apiRef = useGridApiContext();

  const downloadxls = async (data: any) => {
    if (data) {
      const XLSX = await import("xlsx");
      Papa.parse(data, {
        header: true,
        complete: function (results) {
          let ws = XLSX.utils.json_to_sheet(results.data);
          var wscols = [
            { width: 20 },
            { width: 30 },
            { width: 30 },
            { width: 40 },
            { wch: 30 },
            { width: 20 },
            { width: 20 },
            { width: 20 },
            { width: 40 },
            { width: 20 },
            { width: 20 },
            { width: 20 },
          ];
          ws["!cols"] = wscols;
          //ws['!cols'] =  push({ width: 30 })
          ws["!rows"] = [{ hpx: 15, hpt: 15, level: 1 }];
          let wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Clientes");

          XLSX.writeFile(wb, `Minha Lista.xlsx`);
        },
      });
    }
  };
  const handleExport = (options?: GridCsvExportOptions) => {
    //const teste = await apiRef.current.exportDataAsCsv(options);
    const teste2 = apiRef.current.getDataAsCsv(options);
    downloadxls(teste2);
  };

  const buttonBaseProps: ButtonProps = {
    color: "primary",
    size: "small",
    startIcon: <SaveAlt />,
  };

  return (
    <div
      style={{
        padding: "30px 15px 10px",
        background: "black",
        color: "white",
        borderRadius: "10px 10px 0px 0px",
      }}
    >
      <GridToolbarContainer>
        <GridToolbarColumnsButton
          onResize
          onResizeCapture
          sx={{ color: "white" }}
        />
        <GridToolbarFilterButton
          onResize
          onResizeCapture
          sx={{ color: "white" }}
        />
        <GridToolbarDensitySelector
          onResize
          onResizeCapture
          sx={{ color: "white" }}
        />

        <Button
          {...buttonBaseProps}
          sx={{ color: "white" }}
          disabled={!exportTools}
          onClick={() => handleExport({ allColumns: true })}
        >
          Exportar lista completa
        </Button>
      </GridToolbarContainer>
    </div>
  );
}

function getLinkCNPJ(params: GridValueGetterParams) {
  let response = getCNPJ(params);
  return response;
}

export function getFomatedCNPJ(
  params: GridValueGetterParams | string | undefined
) {
  if (params !== undefined) {
    let response = getCNPJ(params);
    response = response.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
    return response;
  }
}

function getCNPJ(params: GridValueGetterParams | string) {
  let cnpj = "";
  if (typeof params !== "string") {
    cnpj = params.getValue(params.id, "cnpj");
  } else {
    cnpj = params;
  }
  //let cnpjOrdem = params.getValue(params.id, 'cnpj_ordem');
  //let cnpjDv = params.getValue(params.id, 'cnpj_dv');
  //cnpjOrdem = cnpjOrdem ? cnpjOrdem : '';
  //cnpjDv = cnpjDv ? cnpjDv : '';
  if (cnpj) {
    cnpj = cnpj!.toString().replace(/[^0-9]/g, "");
  } else {
    cnpj = "";
  }

  //cnpjOrdem = cnpjOrdem?.toString().replace(/[^0-9]/g, '');
  //cnpjDv = cnpjDv?.toString().replace(/[^0-9]/g, '');
  //cnpj = cnpj + cnpjOrdem + cnpjDv;
  return cnpj;
}

function getTelefone1(params: GridValueGetterParams) {
  //let ddd = params.getValue(params.id, 'ddd_1');
  let telefone = params.getValue(params.id, "telefone1");
  telefone = telefone?.toString().replace(/[^0-9]/g, "");

  let response = telefone;

  if (response) {
    if (response.length === 10) {
      response = response.replace(/^(\d{2})(\d{4})(\d{4})/, "($1)$2-$3");
    }
    if (response.length === 9) {
      response = response.replace(/^(\d{2})(\d{4})(\d{3})/, "($1)$2-$3");
    }
    if (response.length === 11) {
      response = response.replace(/^(\d{2})(\d{5})(\d{4})/, "($1)$2-$3");
    }
  } else {
    response = "";
  }
  return response;
}

function getTelefone2(params: GridValueGetterParams) {
  let telefone = params.getValue(params.id, "telefone2");

  telefone = telefone?.toString().replace(/[^0-9]/g, "");

  let response = telefone;

  if (response) {
    if (response.length === 10) {
      response = response.replace(/^(\d{2})(\d{4})(\d{4})/, "($1)$2-$3");
    }
    if (response.length === 9) {
      response = response.replace(/^(\d{2})(\d{4})(\d{3})/, "($1)$2-$3");
    }
    if (response.length === 11) {
      response = response.replace(/^(\d{2})(\d{5})(\d{4})/, "($1)$2-$3");
    }
  } else {
    response = "";
  }
  return response;
}

function getMunicipio(params: GridValueGetterParams) {
  let municipio = params.getValue(params.id, "municipio_nome");
  municipio = municipio?.toString();
  municipio = municipio ? municipio : "";
  let estado = params.getValue(params.id, "uf");
  estado = estado?.toString();
  estado = estado ? estado : "";

  municipio = municipio + " - " + estado;

  return municipio;
}

function getEmail(params: GridValueGetterParams) {
  let email = params.getValue(params.id, "email");
  email = email?.toString();
  email = email ? email : "";
  email = email.toLowerCase();
  return email;
}

function getPorte(params: GridValueGetterParams) {
  let porte = params.getValue(params.id, "porte_empresa_nofilter");
  porte = porte?.toString().replace(/[^0-9]/g, "");

  let value = Number(porte);
  let response = "";

  if (value) {
    if (value === 1) {
      response = " ME - Micro Empresa";
    }
    if (value === 3) {
      response = " EPP - Empresa de Pequeno Porte";
    }
    if (value === 5) {
      response = " Demais";
    }
  } else {
    response = "Não Informado";
  }
  return response;
}

function getCapitalSocial(params: any) {
  let capitalSocial = params.getValue(params.id, "capital_social");
  capitalSocial = capitalSocial?.toString().replace(/[^0-9]/g, "");
  capitalSocial = capitalSocial ? Number(capitalSocial) : "0";

  if (typeof capitalSocial === "number") {
    capitalSocial = capitalSocial.toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
  }

  return capitalSocial;
}

function getQuantSocio(params: GridValueGetterParams) {
  let socios: any = [];
  socios = params.getValue(params.id, "socios");
  socios = socios ? socios : "";

  let quantidade = socios.length;
  //quantidade = quantidade.length.toString();
  return quantidade;
}

export default function TableCNPJ(response: any) {
  const unformatedData = [...response.data];
  const [data, setData] = useState<any>([]);
  const [removeBtn, setRemove] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(25);

  const UrlConsulta = Database();

  const baseURL = UrlConsulta + "/consulta/";

  const { user } = useAuth();
  userID = user?.id ? user.id : "";

  async function getRows(pageChange: number) {
    const initialRow = pageChange * pageSize;

    if (unformatedData.length > 0) {
      let mapTable = await MapTable(unformatedData);

      if (mapTable.length > 0) {
        let formatedTable = await GerarTabela(mapTable, initialRow);

        setData(formatedTable);
      }
      setLoading(false);
    } else {
      setData([]);
      setLoading(false);
    }
  }

  async function consultarDB(valor: any) {
    let responseDB: any = {};
    if (valor) {
      await axios
        .get(baseURL, { params: { nomeFantasiaCNPJ: valor } })
        .then((response) => {
          if (response.data[0] !== null) {
            responseDB = response.data[0];
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
    return responseDB;
  }

  const GerarTabela = async (ArrayTable: any, initialRow: number) => {
    let table = ArrayTable;
    let rows: any = [];

    setLoading(true);
    let j = 0;
    let maxValue = 0;
    if (pageSize + initialRow <= response.count) {
      maxValue = pageSize + initialRow;
    } else {
      maxValue = response.count;
    }
    for (let i = initialRow; i < maxValue; i++) {
      let newRows = await consultarDB(table[i]);
      rows[j] = newRows;

      
      if(newRows !== undefined)
      j++;
    }
    console.log(rows);

    localStorage.setItem("MinhaLista", JSON.stringify(rows as any));
    return rows;
  };

  const MapTable = (value: string[]) => {
    const table = value;

    let rowData: any = [];

    table.map((lista: any, key: any) => {
      let filter = lista.CNPJ ? lista.CNPJ : lista.cnpj;

      if (filter) {
        let cnpj = filter!.toString().replace(/[^0-9]/g, "");
        return (rowData[key] = cnpj);
      }
    });

    return rowData;
  };

  exportTools = user?.isAdmin ? true : response.export;
  if (response.remove) {
    if (response.remove[0] === true) {
      //setRemove(true)
    }
  }

  useEffect(() => {
    getRows(0);

    if (response.remove) {
      if (!!response.remove[0]) {
        setRemove(response.remove[0] ? response.remove[0] : false);
      }

      if (!!response.remove[1]) {
        funcRemove = response.remove[1];
      }
    }
  }, []);

  const columns: GridColDef[] = [
    {
      field: "cnpjformated",
      headerName: "CNPJ",
      minWidth: 150,
      editable: false,
      hide: true,
      flex: 1,
      valueGetter: getFomatedCNPJ,
    },
    {
      field: "razao_social",
      headerName: "Razão Social",
      width: 150,
      //editable: true,
      flex: 2,
      headerClassName: "TableMargin",
      cellClassName: "TableMargin",
    },
    {
      field: "nome_fantasia",
      headerName: "Nome Fantasia",
      width: 150,
      editable: false,
      flex: 2,
    },
    {
      field: "cnae_fiscal_desc",
      headerName: "Cnae Fiscal",
      width: 150,
      editable: false,
      flex: 2,
      hide: true,
    },
    {
      field: "email1",
      headerName: "Email",
      width: 110,
      editable: false,
      valueGetter: getEmail,
      flex: 1,
      hide: true,
    },
    {
      field: "telefone_1",
      headerName: "Telefone 1",
      width: 110,
      valueGetter: getTelefone1,
      editable: false,
      flex: 1,
      hide: true,
    },
    {
      field: "telefone_2",
      headerName: "Telefone 2",
      width: 110,
      valueGetter: getTelefone2,
      editable: false,
      flex: 1,
      hide: true,
    },
    {
      field: "municipio",
      headerName: "Município",
      width: 150,
      editable: false,
      valueGetter: getMunicipio,
      flex: 1,
    },
    {
      field: "endereco",
      headerName: "Endereço",
      width: 150,
      editable: false,
      flex: 2,
      hide: true,
    },

    {
      field: "capital_social",
      headerName: "Capital Social",
      width: 100,
      editable: false,
      renderCell: (params) => {
        let value = getCapitalSocial(params);
        return <>{value}</>;
      },
      flex: 1,
    },
    {
      field: "porte-empresa",
      headerName: "Porte da Empresa",
      width: 100,
      editable: false,
      valueGetter: getPorte,
      flex: 1,
    },
    {
      field: "socios_Quant",
      headerName: "Quantidade de Sócios",
      width: 75,
      align: "center",
      editable: false,
      flex: 1,
      valueGetter: getQuantSocio,
      hide: true,
    },

    {
      field: "button",
      headerName: "Opções",
      editable: false,
      flex: 0.5,
      disableExport: true,
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: getLinkCNPJ,
      renderCell: (params) => {
        let cnpj = params.getValue(params.id, "cnpj");
        return (
          <>
            {removeBtn && (
              <Tooltip title="Remover">
                <Link to={"#"}>
                  <AlertDialogWarning
                    Title="Deletar Usuário"
                    Menssage={"Deseja realmente deletar o " + cnpj}
                    onClick={() => {
                      RemoveList(params.value);
                    }}
                  >
                    <DeleteForeverRounded />
                  </AlertDialogWarning>
                </Link>
              </Tooltip>
            )}
            <Tooltip title="Visualizar">
              <Link
                to={"/dashboard/cnpj/consultar/visualizar/" + params.value}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconButton>
                  <VisibilityRounded />
                </IconButton>
              </Link>
            </Tooltip>
          </>
        );
      },
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          height: 650,
          width: "100%",
          background: "white",
          boxShadow: "0px 4px 5px -1px rgb(0 0 0 / 20%)",
        }}
      >
        <DataGrid
          rows={data}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[25]}
          //checkboxSelection
          disableSelectionOnClick
          loading={loading}
          localeText={GridTraducao}
          paginationMode="server"
          rowCount={response.count}
          onPageChange={(newPage) => getRows(newPage)}
          getRowClassName={(params) => {
            let Situacao_no_filter = params.getValue(
              params.id,
              "situacao_cadastral_nofilter"
            );
            let Situacao = params.getValue(params.id, "situacao_cadastral");
            let varificador = "";
            if (Situacao_no_filter === 2 || Situacao === 2) {
              varificador = "active";
            } else {
              varificador = "desactive";
            }
            let css = "grid-color-" + varificador;
            return css;
          }}
        />
      </div>
    </ThemeProvider>
  );
}
