/***************************************/
/* COLOQUE SEMPRE UM EM BAIXO DO OUTRO */
/***************************************/

import { IVersion } from "./interfaces/version";

export const VersaoServidor: IVersion[] = [
  {
    versao: "1.0.0.0",
    data: "03/11/2021",
    GeralModificado: [
      {
        title: "implementação",
        subtitle: "Foi implementado o changelog",
      },
    ],
    active: true,
    ItemModificado: [
      {
        title: "Dashboard Inicio",
        Modificacaoes: [
          {
            title: "Adicionado",
            subtitle: "Changelog",
          },
        ],
      },
    ],
    extra: [
      {
        title: "AVISO",
        subtitle:
          "Tudo que foi feito anterior a data de implementação não será listado aqui",
      },
    ],
  },
  {
    versao: "1.0.1.0",
    data: "07/12/2021",
    GeralModificado: [
      {
        title: "Melhoria",
        subtitle: "Velocidade de consulta",
      },
    ],
  },

  {
    versao: "1.1.0.0",
    data: "16/12/2021",
    GeralModificado: [
      {
        title: "Implementação",
        subtitle: "Opção de lista adicionada",
      },
    ],
  },
  {
    versao: "1.1.0.1",
    data: "18/03/2022",
    GeralModificado: [
      {
        title: "Correção",
        subtitle: "Verificação das keys",
      },
    ],
  },
  {
    versao: "1.1.1.0",
    data: "31/03/2022",
    GeralModificado: [
      {
        title: "Melhoria",
        subtitle: "Remover e adicionar separado da 'minha lista'",
      },
    ],
  },
  {
    versao: "1.1.2.2",
    data: "28/04/2022",
    GeralModificado: [
      {
        title: "Atualização",
        subtitle: "Banco de dados atualizado",
      },
      {
        title: "Correção",
        subtitle: "Erros na consulta",
      },
      {
        title: "Correção",
        subtitle: "Formato de capital social",
      },
    ],
  },
  {
    versao: "1.1.4.0",
    data: "29/04/2022",
    GeralModificado: [
      {
        title: "Implementação",
        subtitle: "Consulta de CNPJ adicionada",
      },
      {
        title: "Implementação",
        subtitle: "Qualificacões adicionado ao sócio",
      },
    ],
  },
  {
    versao: "1.1.4.1",
    data: "02/05/2022",
    GeralModificado: [
      {
        title: "Correção",
        subtitle: "Geração da lista",
      },
    ],
  },
];
