import { destroyCookie, parseCookies, setCookie } from "nookies";
import { createContext, ReactNode, useEffect, useState } from "react";
import { api } from "../../services/apiClient";
import { ServerAPI } from "../Database/API/server";
import jwt_decode from "jwt-decode";
type User = {
  id?: string;
  name?: string;
  email: string;
  avatar?: string;
  active?: boolean;
  active_at?: Date;
  signature_start?: Date;
  signature_end?: Date;
  isAdmin?: boolean;
};

type UserData = {
  id?: string;
  first_name?: string;
  last_name?: string;
  email: string;
  avatar?: string;
  active?: boolean;
  active_at?: Date;
  signature_start?: Date;
  signature_end?: Date;
  isAdmin?: boolean;
};

type IResult = {
  token: string;
  refresh_token: string;
  user: User;
  client: String[];
};

type signInCredentials = {
  email: string;
  password: string;
};

type AuthContextType = {
  user?: User;
  signOut: () => void;
  SignIN: (resp: any) => Promise<void>;
  CleanCache: () => void;
};

type AuthProviderProps = {
  children: ReactNode;
};

export const AuthContext = createContext({} as AuthContextType);

export function signOut() {
  console.log("logout");

  localStorage.clear();
  destroyCookie(undefined, "b2bAuth.token");
  destroyCookie(undefined, "b2bAuth.refreshToken");
  destroyCookie(undefined, "b2bAuth.user");

  document.cookie.split(";").forEach((c) => {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });

  window.location.href = "/";
  console.log("deslogado");
}

export function CleanCache() {
  console.log("CleanCache");
  let cnae = localStorage.getItem("tableCnae");

  cnae = cnae ? cnae : "";
  for (let storageItem in localStorage) {
    if (storageItem !== "token" && "userID") {
      localStorage.removeItem(storageItem);
    }
  }

  localStorage.setItem("tableCnae", cnae);

  window.location.reload();
}

export function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<User>();

  useEffect(() => {
    const { "b2bAuth.token": token } = parseCookies();

    if (token) {
      api
        .get(`${ServerAPI.pages.users.url}/me`)
        .then((response) => {
          const {
            id,
            first_name,
            last_name,
            email,
            avatar,
            active,
            active_at,
            signature_start,
            signature_end,
            isAdmin,
          } = response.data as UserData;

          setUser({
            id,
            name: first_name + " " + last_name,
            email,
            avatar,
            active,
            active_at,
            signature_start,
            signature_end,
            isAdmin,
          } as UserData);

          if (id) {
            setCookie(undefined, "b2bAuth.user", id);
            localStorage.setItem("userID", id);
          }
        })
        .catch((err) => {
          alert(err);
          signOut();
        });
    }
    if (!!user && !token) {
      signOut();
    }
  }, []);

  async function SignIN(resp: any) {
    const { email: newEmail, password } = resp;

    await api
      .post("sessions", {
        email: newEmail,
        password,
      })
      .then((response) => {
        const { token, refresh_token, user } = response.data as IResult;

        setCookie(undefined, "b2bAuth.token", token, {
          maxAge: 60 * 60 * 24 * 30,
          path: "/",
        });

        setCookie(undefined, "b2bAuth.refreshToken", refresh_token, {
          maxAge: 60 * 60 * 24 * 30, // 30 days
          path: "/",
        });

        const {
          id,
          first_name,
          last_name,
          email,
          avatar,
          active,
          active_at,
          signature_start,
          signature_end,
          isAdmin,
        } = user as any;
        const decoded: any = jwt_decode(token);

        setCookie(undefined, "b2bAuth.user", decoded?.sub);

        setUser({
          id,
          name: first_name + " " + last_name,
          email,
          avatar,
          active,
          active_at,
          signature_start,
          signature_end,
          isAdmin,
        });

        api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

        localStorage.setItem("token", token);
        console.log(user);
      });
  }

  return (
    <AuthContext.Provider value={{ signOut, SignIN, CleanCache, user }}>
      {children}
    </AuthContext.Provider>
  );
}
