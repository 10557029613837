import * as React from 'react';
import axios from 'axios';


/* Styles for MUI */
import {
  TextField,
  CircularProgress,
  Autocomplete
} from '@mui/material';


/* Database */
import { Database } from '../../../../../../../config/Database/database';


interface Filtro {
  id: string;
  label: string;
}


/* Functions */
export default function Cnae({ name, onChange, Namelabel, setForm, form }: any) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<readonly Filtro[]>([]);
  const loading = open && options.length === 0;



  let botaoAtivo = false;

  const UrlConsulta = Database();
  const baseURL = UrlConsulta + "/consulta/cnae/";

  function convert(resp: any) {
    const newArray = resp.map((item: any) => {
      let cnae_Masked = item.codigo;
      cnae_Masked = cnae_Masked.toString().replace(/^(\d{2})(\d{2})(\d{1})(\d{2})/, "$1.$2-$3-$4");
      return ({ id: item.codigo, label: cnae_Masked + " - " + item.descricao });
    });

    setOptions(newArray);

  };

  const updateField = (Value: any) => {
    let ValueDef = Value;
    ValueDef = ValueDef.id ? ValueDef.id : null;
    setForm({
      ...form,
      [name]: Value.id,
    });

  };

  if (form.txtEstado === undefined) {
    botaoAtivo = true;
  }





  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      let verificarTabela = JSON.parse(localStorage.getItem("tableCnae") as any);
      if (!verificarTabela || verificarTabela.length === 0) {
        if (active) {
          await axios.get(baseURL)
            .then(response => {
              localStorage.setItem("tableCnae", JSON.stringify(response.data as any));
              convert(response.data);


            }).catch(() => {
              setOptions([]);
            });
        }
      } else {
        convert(verificarTabela);
      }
    }
    )();


    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete

      isOptionEqualToValue={(option, value) => option.id === value.id}
      sx={{ width: '100%' }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(event: any, newValue) => {
        if (newValue) {
          updateField(newValue);
        }
        else {
          updateField('');
        }
      }}
      getOptionLabel={(option) => option.label}
      options={options}
      loading={loading}

      renderInput={(params) => (
        <TextField
          autoComplete="disabled"
          {...params}
          inputProps={{
            ...params.inputProps,
            autoComplete: "disabled", // disable autocomplete and autofill
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          label={Namelabel} variant="outlined"

        />
      )}
    />
  );
}
