import { IBoardData, IBoardItem } from "../../../hooks/Context";

export async function deleteBoard(id: string | number, newBoard: IBoardData[]): Promise<IBoardData[] | undefined> {
  
  if (window.confirm('Excluir Board?')) {
      let boardsFinal = await JSON.parse(JSON.stringify(newBoard))

      await boardsFinal.find((p: IBoardItem, key: number) => p.id === id && (boardsFinal.splice(key, 1)));

      return boardsFinal;
      
    }
  }