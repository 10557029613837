import { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { useAppContext } from '../../hooks/Context';
import { saveBoardAction } from '../../hooks/kanban';

export default function SaveButton() {
  const [loading, setLoading] = useState('idle');
  const [newBoard] = useAppContext()
  const [loadingPage, setLoadingPage] = useState(false);

  async function save(){
    setLoading('progress');
    await saveBoardAction(newBoard).then(()=>{
      setLoading('success');
    }).catch(()=>{
      setLoading('error');
    });
  }
  
  useEffect(() =>{
      if(newBoard.length > 0 && loadingPage){
        save();
    }
    setTimeout(() => setLoadingPage(true), 3000);
    
  },[newBoard])

  return (
    <Box sx={{ display: 'flex', height: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
      <Box sx={{ height: '100%',  display: 'flex', alignItems: 'center', marginRight: '10px'  }}>
        {loading === 'success' && (<Typography>Salvo com sucesso!</Typography>)}
        {loading === 'error' && (<Typography>Erro ao salvar!</Typography>)}
        {loading === 'progress' && (
          <Fade
            in={loading === 'progress'}
            style={{
              transitionDelay: loading === 'progress' ? '0ms' : '0ms',
            }}
            unmountOnExit
          >
            <CircularProgress size={30}/>
          </Fade>
        )}
      </Box>
        <Button onClick={() => save()}>
          Salvar
        </Button>

    </Box>
  );
}
