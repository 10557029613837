import { createId, IBoardData } from "../../../hooks/Context"

export function createBoardAction(newBoard: any, setNewBoard: any) {
    let count = newBoard?.length + 1
    
    let board = {
      id: createId(),
      name: 'Novo Board ' + count,
      position: count - 1,
      items: [],
      created_at: new Date()
    }
    newBoard = newBoard?? [];
    let Board = JSON.parse(JSON.stringify(newBoard));
    let teste = Board.find((verify:IBoardData, key: number) => verify.name === board.name && true)
    while(!!teste){
      count++;
      
      board = {
        id: createId(),
        name: 'Novo Board ' + count,
        position: Board.length,
        items: [],
        created_at: new Date()
      }
      teste = Board.find((verify:IBoardData, key: number) => verify.name === board.name && 
      true)
    }

    setNewBoard([...Board, board])
  
}