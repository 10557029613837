import { useAuth } from "../../config/Hooks/userAuth";

import IconPhone from "../../image/Celphone0800.png";

/* Components */

import { default as LoginForm } from "./components/form";

/* Styles */
import { useThemeTemplate } from "../../config/Contexts/Theme";
import { Button } from "@mui/material";

import "./style.scss";
import { verifyUserLogin } from "../../config/Hooks/verifyLogin";

/* Functions */
export function LoginPage() {
  const { Logo, Title } = useThemeTemplate().theme;
  // const { Primary } = useThemeTemplate().theme.Colors;
  const {
    Image,
    Welcome,

    RegisterLink,

    subTitle,
    TitleLogin,
  } = useThemeTemplate().theme.Text.Login;
  const { user } = useAuth();
  let verify = verifyUserLogin();

  return (
    <div className="page-total-area">
      <div className="container">
        <div className="AreaSides">
          <div className="left-side">
            <p>{subTitle}</p>
            <h1>{TitleLogin}</h1>
            <div className="CelphoneArea">
              <img src={IconPhone} alt="Icone de telefone 0800" />
              <a href="tel:08000003184">
                <p>0800 000 3184</p>
              </a>
            </div>
          </div>
          <div className="right-side">
            <header className="header-b2b-right-side">
              <img
                src={Logo}
                alt={"logo " + Title}
                style={{ maxWidth: "130px" }}
              />
            </header>
            <div className="content-right-side">
              <div className="welcome-area">
                {verify ? (
                  <h2>Bem-vindo de volta {user?.name ? user?.name : "..."}</h2>
                ) : (
                  <h2>{Welcome} </h2>
                )}
                {verify ? (
                  ""
                ) : (
                  <p>Insira seus dados abaixo para acessar a plataforma.</p>
                )}
              </div>
              {verify ? (
                <div className="separator">
                  Clique no botão abaixo para acessar a plataforma
                </div>
              ) : (
                <div className="separator">Logue com sua conta</div>
              )}
              <div className="FormLogin">
                <LoginForm />
              </div>
              <div className="NoAccount">
                {verify ? (
                  ""
                ) : (
                  <p>
                    Não possui uma conta?
                    <Button className="btnCadastre" href={RegisterLink}>
                      Cadastre-se
                    </Button>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
