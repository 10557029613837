import { Box, SvgIcon } from "@mui/material"
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { LinkIcon } from "./LinkIcon";

interface IProps {
    name?: string
}


const RedesSociais = ({name}: IProps) =>{

    if(name){
    return (
        <Box sx={{margin: "0 !important", height: 30}}>
            <LinkIcon name={name} site="facebook.com" Icon={<FacebookIcon />}/> 
            <LinkIcon name={name} site="linkedin.com" Icon={<LinkedInIcon />}/> 
            <LinkIcon name={name} site="instagram.com" Icon={<InstagramIcon />}/> 
            <LinkIcon name={name} site="twitter.com" Icon={<TwitterIcon />}/> 
            <LinkIcon name={name} site="youtube.com" Icon={<YouTubeIcon />}/> 
        </Box>
    )
}
else{
    return(<></>);
}
}


export { RedesSociais }