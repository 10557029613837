import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom";
import axios from 'axios';
import Moment from 'moment';
import 'moment/locale/pt-br';
/* Hooks and Databse */
import { useAuth } from '../../../../../../config/Hooks/userAuth';
import { useWindowSize } from '../../../../../../config/Hooks/resizeDisplay';
import { Database } from '../../../../../../config/Database/database';


/* Styles for MUI */
import {
    LinearProgress,
    Alert,
    AlertTitle,
    Stack,
    Button,
    Box,
    Paper,
    Grid,
    Typography,
    Tooltip,
    IconButton,
    styled as styledMUI,
    CircularProgress
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import {
    VisibilityRounded,
    Add,
    Check
} from '@mui/icons-material';
import CallIcon from '@mui/icons-material/Call';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SearchIcon from '@mui/icons-material/Search';
import EmailIcon from '@mui/icons-material/Email';
import MapIcon from '@mui/icons-material/Map';
/* Components */
import SocioLayout from "./components/Socio/";


/* Styles */
import './style.scss';
import { useThemeTemplate } from '../../../../../../config/Contexts/Theme';
import { LinkIcon } from '../../../../components/social/LinkIcon';
import { api } from '../../CRM/hooks/apiClient';
import { parseCookies } from 'nookies';


const Item = styledMUI(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));
const UrlConsulta = Database();
let carregando = 0;

export function Visualizar() {
    const size = useWindowSize();
    const params = useParams<any>();
    const { user } = useAuth();
    const { Primary } = useThemeTemplate().theme.Colors;
    let urlID = params.id ? params.id : "";


    // Define todas as constantes que podem ser atualizadas
    const [Basedata, setData] = useState<any>([]);
    const [filialdata, setFilial] = useState<any>([]);
    const [sociosoData, setSocios] = useState<any>([]);
    const [checkKanbanLoading, setCheckKanbanLoading] = useState<any>(false);
    const [checkMinhaLista, setMinhaLista] = useState<any>();
    const [checkKanban, setKanban] = useState<any>()
    // Tira todos as letras antes da consulta
    let cnpjparams = params.id;
    cnpjparams = cnpjparams.toString().replace(/[^0-9]/g, '');
    // Define a "ordem" do cnpj
    const cnpjordem = cnpjparams.substr(8, 4);

    // puxa somente a quantidade de digitos necessária do cnpj 
    cnpjparams = cnpjparams.substr(0, 8);

    //Limpa a base do url
    const baseURL = UrlConsulta + "/consulta/cnpj/" + cnpjparams + "/" + cnpjordem;

    //Define o dominio que será consultado (DÁ PARA MELHORAR)


function RemoveSpecialCharacter(param: string){
    let text = param ? param : "";
    let textFormated = text.replace(/[^a-zA-Z0-9]/g, '');

    return textFormated;

}


    function FormatNumber(param: any) {
        let numberEntry = (param ? param : "");

        let formatarNumero = RemoveSpecialCharacter(numberEntry);

        if (formatarNumero.length === 9) {
            formatarNumero = formatarNumero.toString().replace(/^(\d{2})(\d{3})(\d{4})/, "($1)$2-$3");
        }
        else if (formatarNumero.length === 10) {
            formatarNumero = formatarNumero.toString().replace(/^(\d{2})(\d{4})(\d{4})/, "($1)$2-$3");
        }
        else if (formatarNumero.length === 11) {
            formatarNumero = formatarNumero.toString().replace(/^(\d{2})(\d{5})(\d{4})/, "($1)$2-$3");
        }

        return formatarNumero;
    }

    function currencyFormat(num: number) {
        var f = num.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
        return f;
    }
    function CnaeFormat(param: any) {
        let cnae_number = param.toString().replace(/^(\d{2})(\d{2})(\d{1})(\d{2})/, "$1.$2-$3-$4");
        let cnae_name = CnaeConsulta(param);

        let cnae_format = cnae_number;

        if (cnae_name) {
            cnae_format = cnae_number + " - " + cnae_name;
        }
        return cnae_format;
    }

    function CnaeConsulta(param: any) {
        let cnae_memoria: any = "";
        if (localStorage.getItem("tableCnae")) {
            cnae_memoria = JSON.parse(localStorage.getItem("tableCnae") as any);
        }

        if (cnae_memoria) {
            let index = "";
            let cnae_escolhido = cnae_memoria;
            for (var i in cnae_escolhido) {
                if (cnae_escolhido[i].codigo === param) {
                    index = cnae_escolhido[i].descricao;
                    break; // suspende o laço caso tenha encontrado uma ocorrência
                }
            }

            return index;
        }
        else {

        }

    }
    function DateFormate(param: any) {
        let date_f = param ? param : "";

        if (date_f) {
            date_f = Moment(date_f, "YYYY-MM-DD").format('DD/MM/YYYY');
        }
        return date_f;
    }

    function CopyToClipBoard(copyText: any) {
        navigator.clipboard.writeText(copyText);
        alert("CNPJ: " + copyText + " Copiado");
    }


    function MinhaLista() {
        //checkMinhaLista ? setMinhaLista(false) : setMinhaLista(true);
        let userid = user?.id ? user?.id : "";
        let URLAdd = UrlConsulta + "/user/lista/add";
        let URLRemove = UrlConsulta + "/user/lista/remove";
        let URL = checkMinhaLista ? URLRemove : URLAdd;
        //let testURL = "http://localhost:3001/user/AddRemove/";
        const AddList = async () => {
            await axios.get(URL, { params: { userID: userid, cnpj: params.id } })
                .then(response => {
                    /*localStorage.setItem("user/cnpj/" + urlID, JSON.stringify(response.data as any));
                    setData(response.data);
                    carregando = 1;*/
                    checkMinhaLista ? setMinhaLista(false) : setMinhaLista(true);
                })

        }
        AddList();
    }
    function meuKanban() {
        //checkMinhaLista ? setMinhaLista(false) : setMinhaLista(true);
        let userid = user?.id ? user?.id : "";
        let URLAdd = "/kanban/item/";
        let URLRemove = "/kanban/item/delete";
        let URL = checkKanban ? URLRemove : URLAdd;
        let values = { cnpj: params.id, priority: 0 };
        const AddItem = async () => {
            await api.post(URL, values )
                .then(response => {
                    Verify()
                    /*localStorage.setItem("user/cnpj/" + urlID, JSON.stringify(response.data as any));
                    setData(response.data);
                    carregando = 1;*/
                    checkMinhaLista ? setKanban(false) : setKanban(true);
                })

        }
        AddItem();
    }
    

    useEffect(() => {
        let SocioURL = UrlConsulta + "/consulta/socios/";
        let verificarTabela = ""
        if (localStorage.getItem("visualizar/" + urlID)) {
            verificarTabela = JSON.parse(localStorage.getItem("visualizar/" + urlID) as any);
        }


        const GetSocio = async () => {
            await axios.get(SocioURL, { params: { cnpj: cnpjparams } })
                .then(response => {
                    setSocios(response.data);
                })
        }
        //Consulta os dados do CNPJ
        if (!verificarTabela) {
            const dataGet = async () => {
                await axios.get(baseURL)
                    .then(response => {
                        localStorage.setItem("visualizar/" + urlID, JSON.stringify(response.data as any));
                        setData(response.data);
                        carregando = 1;
                        GetSocio();

                    })
            }
            dataGet();

        }
        else {
            setData(verificarTabela);
            GetSocio();
        }

        carregando = 0;
    }, [params])

    useEffect(() => {

        //Define a URL de consulta das filiais
        let filialURL = UrlConsulta + "/consulta/cnpj/" + cnpjparams;
        //Consulta os dados da Filial
        const filialGet = async () => {
            await axios.get(filialURL)
                .then(response => {
                    setFilial(response.data);
                })
        }

        filialGet();



    }, [])

    async function Verify() {
        const { "b2bAuth.user": user} = parseCookies();
        let userIdLocal = user;
        //Define a URL de consulta das filiais
        let filialURL = UrlConsulta + "/user/cnpj/";
        //let testURL = "http://localhost:3001/user/cnpj/";
        //Consulta os dados da Filial
        if (userIdLocal) {
            const VerifyList = async () => {
                await axios.get(filialURL, { params: { userID: userIdLocal, cnpj: params.id } })
                    .then(response => {
                        let verify: any = response.data;
                        if (verify.length !== 0) {
                            setMinhaLista(true);
                        }
                        else {
                            setMinhaLista(false);
                        }


                    })
            }
            const VerifyKanban = async () => {
                setCheckKanbanLoading(true);
                await api.post("/kanban/item/verify", {cnpj: params.id })
                    .then(response => {
                        
                        let verify: any = response.status;
                        if (verify === 201) {
                            setKanban(() => true);
                        }
                        else {
                            setKanban(()=> false);
                        }


                    }).finally(()=>{
                        setCheckKanbanLoading(false);
                    })
            }
            VerifyKanban();
            VerifyList();
        }
    }

    useEffect(() => {
        Verify();


    }, [])


    let data = Basedata[0] ? Basedata[0] : "";
    if (!data) {
        //Retorna um erro caso não possua um CNPJ
        if (!carregando) {
            return (
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            );
        }
        else {
            return (
                <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        Por favor, verifique o <strong>CNPJ!</strong>
                    </Alert>
                </Stack>
            );
        }
    } else {

        // Formata o CNPJ
        let CNPJ_Formated = data.cnpj;
        if (!CNPJ_Formated) {
            CNPJ_Formated = "";
        }
        let Filial_Matriz = data.cnpj_ordem === "0001" ? 'Matriz' : 'Filial';
        //Formata a Razão Social
        let Razao_Formated = (data.razao_social ? data.razao_social : "");

        //Formata o Nome Fantasia
        let Nome_Formated = (data.nome_fantasia ? data.nome_fantasia : "");

        //Formata o CNAE FISCAL
        let CNAEFiscal = (data.cnae_fiscal ? data.cnae_fiscal : "");
        let CNAE_Formated = CnaeFormat(CNAEFiscal);

        // Formata o CNAE Secundario
        const cnae_sec = data.cnae_secundario ? data.cnae_secundario.split(",") : "";


        //Formata o Porte da empresa
        let Porte_Formated = data.porte_empresa ? data.porte_empresa : "";

        //Formata o Capital da empresa
        let Capital_Formated = currencyFormat(data.capital_social ? data.capital_social : "");

        //Formata o Telefone 1
        let Telefone1 = data.telefone1 ? data.telefone1 : "";
        Telefone1 = RemoveSpecialCharacter(Telefone1);
        let Telefone1_Formated = FormatNumber(Telefone1);

        //Formata o Telefone 2
        let Telefone2 = data.telefone2 ? data.telefone2 : "";
        Telefone2 = RemoveSpecialCharacter(Telefone2);
        let Telefone2_Formated = FormatNumber(Telefone2);

        //Formata o Email
        let Email_Formated = (data.email ? data.email : "");
        Email_Formated = Email_Formated.toLowerCase();

        //Formata a Data De abertura
        let DataAbertura = (data.inicio_atividade ? data.inicio_atividade : "");
        let DataAbertura_Formated = DateFormate(DataAbertura);

        //Formata a Data De abertura
        //let DataFechamento = (data.inicio_atividade ? data.inicio_atividade : "");
        //let DataFechamento_Formated = DateFormate(DataFechamento);

        //Formata o Endereço
      
        let Endereco_Formated = data.endereco ? data.endereco : "";


        return (
            <div>
                <Paper sx={{ flexGrow: 1, marginBottom: 1, borderBottom: 1 + "px solid #cdcdcd", paddingTop: 2, paddingBottom: 2 }} elevation={0} className="socio-list-group" style={{ backgroundColor: Filial_Matriz === "Matriz" ? Primary : "#fff", color: Filial_Matriz === "Matriz" ? "#fff" : "#000" }}>
                    <Grid container>
                        <Grid item xs={11} container sx={{ justifyContent: "center", alignItems: "center" }}>
                            <p> CNPJ: <button className="ClipButton" style={{ color: Filial_Matriz === "Matriz" ? "#fff" : "#000" }} onClick={() => {
                                CopyToClipBoard(CNPJ_Formated);
                            }}><b>{CNPJ_Formated ? CNPJ_Formated : "Carregando"}</b></button> - {Filial_Matriz}</p> <Check />

                        </Grid>
                        <Grid item xs={1} container sx={{ justifyContent: "center", alignItems: "center" }}>

                            <a href={"https://servicos.receita.fazenda.gov.br/Servicos/cnpjreva/Cnpjreva_Solicitacao.asp?cnpj=" + CNPJ_Formated} target="_bank">
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="start"
                                    sx={{ mr: 3 }}
                                >
                                    <Tooltip title="Receita Federal" >
                                        <VisibilityRounded sx={{ color: "white" }} />
                                    </Tooltip>
                                </IconButton>
                            </a>
                        </Grid>
                    </Grid>
                </Paper>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}
                        direction={size.width < 956 ? "column" : "row"}>

                        <Grid item xs={4}>
                            <Item>
                                <div className="redes-sociais--top blocoVisualizar">
                                    <div>
                                        <span>
                                            <h2>{Razao_Formated ? Razao_Formated : "Carregando"}</h2>
                                        </span>
                                    </div>
                                    <p>{CNAE_Formated}</p>
                                </div>

                                <div className="redes-sociais--top blocoVisualizar">
                                    <div className="visualizarAddLista">
                                        <Button variant="outlined"
                                            className="ButtonLista"
                                            onClick={() => {
                                                MinhaLista();
                                            }}
                                            sx={{ color: checkMinhaLista? 'red' : 'black' }} endIcon={checkMinhaLista? <CloseIcon sx={{ color: 'red' }} /> : <CloseIcon sx={{ color: 'red' }} />}>
                                            {checkMinhaLista? 'Remover da lista' : 'Adicionar a lista'}
                                        </Button>
                                        {checkMinhaLista && <Link to={"/dashboard/cnpj/minhalista"}><h3 className='ButtonLista'>Ver Lista</h3></Link>}
                                    </div>
                                    <div className="visualizarAddLista">
                                    {!checkKanbanLoading ? (checkKanban ? (
                                        <>
                                            <Button variant="outlined"
                                                className="ButtonLista"
                                                onClick={() => {
                                                    meuKanban();
                                                }}
                                                sx={{ color: 'red' }} endIcon={<CloseIcon sx={{ color: 'red' }} />}>
                                                Remover do Kanban
                                            </Button>
                                            <Link to={"/dashboard/cnpj/kanban"}><h3 className='ButtonLista'>Ver Kanban</h3></Link>
                                        </>

                                    ) : (<Button variant="outlined"
                                        className="ButtonLista"
                                        onClick={() => {
                                            meuKanban();
                                        }}
                                        sx={{ color: 'black' }} endIcon={<Add sx={{ color: 'black' }} />}>
                                        Adicionar ao Kanban
                                    </Button>
                                    )) :  <CircularProgress size={20}/>}
                                    </div>
                                </div>


                            </Item>


                            <Item sx={{ marginTop: 15 + 'px' }}>
                                <div className="redes-sociais--top blocoVisualizar">
                                    <div>
                                        <span>
                                            <h2>Sócios</h2>
                                        </span>
                                    </div>
                                    {
                                        sociosoData.length >= 1 ? (
                                            sociosoData.map((val: any, key: any) => {

                                                if (val) {
                                                    return (
                                                        <SocioLayout data={val} />)
                                                } else {
                                                    return null;
                                                }
                                            }
                                            )) : (
                                            <div className="semFilial">Esta empresa não possui sócios.</div>
                                        )}
                                   
                                </div>
                            </Item>
                        </Grid>
                        <Grid item xs={8}>
                            <Item>
                                <div className="informacoesEmpresa">
                                    <div className="blocoVisualizar">
                                        <span>
                                            <h2>Informações da Empresa</h2>
                                        </span>
                                    </div>
                                    <p></p>
                                    <div className="listaVisualizar">
                                        <ul>
                                            {Razao_Formated ? (
                                                <li>
                                                    <div className="tituloLista">Razão Social</div>
                                                    <div className="conteudoLista">
                                                        <span>{Razao_Formated ? Razao_Formated : "Carregando"}</span> 
                                                        <LinkIcon name={Razao_Formated} Icon={<SearchIcon />} Width={30}/>
                                                    </div>
                                                </li>
                                            ) : ""}
                                            {Nome_Formated ? (
                                                <li>
                                                    <div className="tituloLista">Nome Fantasia</div>
                                                    <div className="conteudoLista">
                                                        <span>{Nome_Formated}</span>
                                                        <LinkIcon name={Nome_Formated} Icon={<SearchIcon />} Width={30}/>
                                                    </div>
                                                </li>) : ""}
                                            {Porte_Formated ? (
                                                <li>
                                                    <div className="tituloLista">Porte da Empresa</div>
                                                    <div className="conteudoLista">
                                                        <span>{Porte_Formated ? Porte_Formated : "Carregando"}</span>
                                                    </div>
                                                </li>) : ""}
                                            {Capital_Formated ? (
                                                <li>
                                                    <div className="tituloLista">Capital Social</div>
                                                    <div className="conteudoLista">
                                                        <span>{Capital_Formated ? Capital_Formated : "Carregando"}</span>
                                                    </div>
                                                </li>
                                            ) : ""}
                                            {(Telefone1_Formated && Telefone2_Formated) ? (
                                                <li className="linhaDupla">
                                                    {Telefone1_Formated ? (
                                                        <div className="divDupla">
                                                            <div className="tituloLista">Telefone (1):</div>
                                                            <div className="conteudoLista">
                                                                <span>{Telefone1_Formated}</span> 
                                                                <LinkIcon url={"tel:" + Telefone1} Icon={<CallIcon />} Width={25}/>
                                                                <LinkIcon url={"https://wa.me/+55" + Telefone1} Icon={<WhatsAppIcon />} Width={25}/>
                                                                
                                                            </div>
                                                        </div>
                                                    ) : ""}
                                                    {Telefone2_Formated ? (
                                                        <div className="divDupla">
                                                            <div className="tituloLista secundario">Telefone (2):</div>
                                                            <div className="conteudoLista">
                                                                <span>{Telefone2_Formated}</span> 
                                                                <LinkIcon url={"tel:" + Telefone2} Icon={<CallIcon />} Width={25}/>
                                                                <LinkIcon url={"https://wa.me/+55" + Telefone2} Icon={<WhatsAppIcon />} Width={25}/>
                                                            </div>
                                                        </div>
                                                    ) : ""}
                                                </li>
                                            ) : (
                                                (Telefone1_Formated ? (
                                                    <li>
                                                        <div className="tituloLista">Telefone (1):</div>
                                                        <div className="conteudoLista">
                                                            <span>{Telefone1_Formated}</span> 
                                                            <LinkIcon url={"tel:" + Telefone1} Icon={<CallIcon />} Width={25}/>
                                                            <LinkIcon url={"https://wa.me/+55" + Telefone1} Icon={<WhatsAppIcon />} Width={25}/>
                                                            
                                                        </div>
                                                    </li>
                                                ) : "")
                                            )}
                                            {Email_Formated ? (
                                                <li>
                                                    <div className="tituloLista">Email</div>
                                                    <div className="conteudoLista">
                                                        <span>{Email_Formated}</span> 
                                                        <LinkIcon url={"mailto:" + Email_Formated} Icon={<EmailIcon />} Width={25}/>
                                                    </div>
                                                </li>) : ""}

                                            {Endereco_Formated ? (
                                                <li>
                                                    <div className="tituloLista">Endereço:</div>
                                                    <div className="conteudoLista">
                                                        <span>{Endereco_Formated}</span> 
                                                        <LinkIcon url={"https://www.google.com/maps/search/" + Endereco_Formated} Icon={<MapIcon />} Width={25}/>
                                                        
                                                    </div>
                                                </li>) : ""}
                                            {DataAbertura_Formated ? (
                                                <li>
                                                    <div className="tituloLista">Data de Abertura:</div>
                                                    <div className="conteudoLista">
                                                        <span>{DataAbertura_Formated ? DataAbertura_Formated : "Carregando"}</span>
                                                    </div>
                                                </li>) : ""}

                                        </ul>
                                    </div>
                                </div>
                            </Item>


                            <Grid container spacing={2} marginTop={2 + "px"}>
                                <Grid item xs={12} md={6}>
                                    <Item>
                                        <div className="blocoVisualizar">
                                            <span>
                                                <h2>Filiais</h2>
                                            </span>
                                        </div>

                                        <div>

                                            {
                                                filialdata.length >= 2 ? (
                                                    filialdata.map((val: any, key: any) => {

                                                        if (val && (val.cnpj_ordem !== cnpjordem)) {
                                                            return (
                                                                <Paper className="filialBloco" key={key}>
                                                                    <Grid container wrap="nowrap" spacing={2} >
                                                                        <Grid item xs={10} spacing={5}>
                                                                            <Typography noWrap className="nomeFilial">{val.nome_fantasia ? val.nome_fantasia : val.razao_social}</Typography>
                                                                            <Typography noWrap className="cidadeFilial"><span>{val.cnpj_ordem === "0001" ? 'Matriz' : 'Filial'}</span>{val.municipio_nome} - {val.uf}</Typography>
                                                                        </Grid>
                                                                        <Grid item xs={2} className="visualizarFilial">
                                                                            <Tooltip title="Visualizar">
                                                                                <Link to={'/dashboard/cnpj/consultar/visualizar/' + RemoveSpecialCharacter(val.cnpj)}>
                                                                                    <IconButton>
                                                                                        <VisibilityRounded />
                                                                                    </IconButton>
                                                                                </Link>
                                                                            </Tooltip>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Paper>)
                                                        } else {
                                                            return null;
                                                        }

                                                    }
                                                    )) : (
                                                    <div className="semFilial">Esta empresa não possui nenhuma filial.</div>
                                                )}



                                        </div>
                                    </Item>

                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Item>
                                        <div className="blocoVisualizar">
                                            <span>
                                                <h2>Cnae Secundário</h2>
                                            </span>
                                        </div>

                                        <div>

                                            {
                                                cnae_sec.length >= 2 ? (
                                                    cnae_sec.map((val: any, key: any) => {

                                                        return (
                                                            <div className="semFilial" key={key}>{CnaeFormat(val)}</div>)
                                                    }


                                                    )) : (
                                                    <div className="semFilial">Esta empresa não possui Cnae Secundário</div>
                                                )}



                                        </div>
                                    </Item>

                                </Grid>
                            </Grid>
                        </Grid>



                    </Grid>
                </Box>


                <div>
                    <div>



                    </div>

                </div>
            </div>
        )
    }
}
