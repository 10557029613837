import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import ReactGA from "react-ga";

const useGaTracker = () => {
    const location = useLocation();
    const [isInitialized, setIsInitialized] = useState(false);

    useEffect(() => {
        if (!window.location.href.includes("localhost")) {
            ReactGA.initialize("UA-64656475-53");
        }
        setIsInitialized(true);
    }, [])

    useEffect(() => {
        if (isInitialized) {
            ReactGA.pageview(location.pathname + location.search);
        }
    }, [isInitialized, location]);
};

export default useGaTracker;