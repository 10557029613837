import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import axios from 'axios';


/* Styles for MUI */
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';

import {
    Box,
    Paper,
    Grid,
    styled
} from '@mui/material';


/* Components */
import TableCNPJ from './components/Tabela';


/* DataBase */
import { Database } from '../../../../../../config/Database/database';


/* Traduçõrd */
import 'moment/locale/pt-br';


/* Styles */
import './style.scss';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));



/* Functions */
function CPF_Format(param: any) {
    let test = param;
    if (test) {
        let CPF = test.replace(/(\d{0})?(\d{3})?(\d{3})/, "$1.$2.$3-")
        return CPF;
    }
    else {
        return param;
    }
}

export function VisualizarSocio() {
    const params = useParams<any>();
    const UrlConsulta = Database();

    const [sociosData, setSocios] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);

    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

    let nameSocio = params.name;
    let cpfSocio = params.cpf;

    let carregando = 0;
    // params: { cnpj: cnpjparams }


    useEffect(() => {
        let SocioURL = UrlConsulta + "/consulta/socioData/";

        const GetSocio = async () => {
            await axios.get(SocioURL, { params: { nome: nameSocio, cpf_cnpj: cpfSocio } })
                .then(response => {
                    setSocios(response.data);
                    carregando = 1;
                    setLoading(false)

                }).catch(() => {
                    setLoading(false)
                })
        }
        GetSocio();
        carregando = 0;
    }, [params])

    let data = sociosData[0] ? sociosData[0] : "";

    let CPFFormat = CPF_Format(data.cpf_socio ? data.cpf_socio : "");

    if (!data) {
        //Retorna um erro caso não possua um CNPJ
        if (!carregando) {
            return (
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            );
        }
        else {
            return (
                <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        Por favor, verifique os <strong>Dados do Sócio!</strong>
                    </Alert>
                </Stack>
            );
        }
    } else {
        return (
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Item sx={{ textAlign: 'center' }}><b>{data.nome_socio}</b> ({CPFFormat})</Item>
                    </Grid>

                    {/*<Grid item xs={12} md={4}>
                <Item>Dados: 

                <ListItem disablePadding>
                    <ListItemText primary="Tabela" sx={{ width: '25%', minWidth: '50px', maxWidth: '190px'}}/>
                    <ListItemText primary="Resposta" sx={{ width: '75%'}}/>
                </ListItem>
                <Divider/>
                </Item>
        </Grid>*/}
                    <Grid item xs={12} md={12}>
                        <Item>Empresas Associadas
                            <TableCNPJ data={sociosData} loading={loading} />

                        </Item>
                    </Grid>
                    {/*<Grid item xs={12} md={4}>
                <Item>Socios Vinculos</Item>
              </Grid>
              <Grid item xs={12} md={8}>
                <Item>sociograma</Item>
    </Grid>*/}
                </Grid>
            </Box>
        )
    }
}
