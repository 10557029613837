import { IBoardData } from "../../../hooks/Context";

export function deleteCard(id: string | number, newBoard: any, setNewBoard: any) {

    let cardsFinal = JSON.parse(JSON.stringify(newBoard))
    if (window.confirm("Excluir Card?")) {
      cardsFinal.forEach((board: IBoardData) => board.items.forEach((items: any, index: any) => {
        if (items.id === id) {
          board.items.splice(index, 1)
        }
      }));
      setNewBoard(cardsFinal)
    }
  }