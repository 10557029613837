import { Link } from "react-router-dom";

import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  GridCellParams,
} from "@mui/x-data-grid";

import "./style.scss";
/* Importa a Data Grid */
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";

/* Importa o Material */
import { Tooltip, IconButton, ThemeProvider, createTheme } from "@mui/material";

/* Importa Icones da Material */
import { VisibilityRounded } from "@mui/icons-material";

import { ptBR } from "@mui/material/locale";

import { GridTraducao } from "../../../../../../../../config/Geral/Traducao";
import { useState } from "react";

const theme = createTheme(
  {
    palette: {
      primary: { main: "#1976d2" },
    },
  },
  ptBR
);

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton onResize onResizeCapture />
      <GridToolbarFilterButton onResize onResizeCapture />
      <GridToolbarDensitySelector onResize onResizeCapture />
    </GridToolbarContainer>
  );
}

const columns: GridColDef[] = [
  {
    field: "cnpjformated",
    headerName: "CNPJ",
    minWidth: 150,
    editable: false,

    flex: 1,
    valueGetter: getFomatedCNPJ,
  },
  {
    field: "razao",
    headerName: "Razão Social",
    width: 150,
    editable: false,
    flex: 2,
  },
  {
    field: "nome",
    headerName: "Nome Fantasia",
    width: 150,
    editable: false,
    flex: 2,
  },
  {
    field: "email1",
    headerName: "Email",
    width: 110,
    editable: false,
    valueGetter: getEmail,
    flex: 1,
    hide: true,
  },
  {
    field: "telefone1",
    headerName: "Telefone 1",
    width: 110,
    valueGetter: getTelefone1,
    editable: false,
    flex: 1,
    hide: true,
  },
  {
    field: "telefone2",
    headerName: "Telefone 2",
    width: 110,
    valueGetter: getTelefone2,
    editable: false,
    flex: 1,
    hide: true,
  },
  {
    field: "button",
    headerName: "Ação",
    editable: false,
    flex: 0.5,
    disableExport: true,
    valueGetter: getLinkCNPJ,
    renderCell: (params: GridCellParams) => (
      <Tooltip title="Visualizar">
        <Link to={"/dashboard/cnpj/consultar/visualizar/" + params.value}>
          <IconButton>
            <VisibilityRounded />
          </IconButton>
        </Link>
      </Tooltip>
    ),
  },
];
function getLinkCNPJ(params: GridValueGetterParams) {
  let response = getCNPJ(params);
  return response;
}

function getFomatedCNPJ(params: GridValueGetterParams) {
  let response = getCNPJ(params);
  let cnpjOrdem = params.getValue(params.id, "cnpj_ordem");
  response = response.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    "$1.$2.$3/$4-$5"
  );
  if (cnpjOrdem == "0001") {
    response = response + " - Matriz";
  } else {
    response = response + " - Filial";
  }
  return response;
}
function getCNPJ(params: GridValueGetterParams) {
  let cnpj = params.getValue(params.id, "cnpj");
  let cnpjOrdem = params.getValue(params.id, "cnpj_ordem");
  let cnpjDv = params.getValue(params.id, "cnpj_dv");
  cnpjOrdem = cnpjOrdem ? cnpjOrdem : "";
  cnpjDv = cnpjDv ? cnpjDv : "";
  cnpj = cnpj!.toString().replace(/[^0-9]/g, "");
  cnpjOrdem = cnpjOrdem?.toString().replace(/[^0-9]/g, "");
  cnpjDv = cnpjDv?.toString().replace(/[^0-9]/g, "");
  cnpj = cnpj + cnpjOrdem + cnpjDv;
  return cnpj;
}

function getTelefone1(params: GridValueGetterParams) {
  let ddd = params.getValue(params.id, "ddd_1");
  let telefone = params.getValue(params.id, "telefone_1");
  ddd = ddd ? ddd : "";
  telefone = telefone ? telefone : "";
  ddd = ddd?.toString().replace(/[^0-9]/g, "");
  telefone = telefone?.toString().replace(/[^0-9]/g, "");
  telefone = ddd + telefone;
  let response = telefone;
  if (response.length === 10) {
    response = response.replace(/^(\d{2})(\d{4})(\d{4})/, "($1)$2-$3");
  }
  if (response.length === 9) {
    response = response.replace(/^(\d{2})(\d{4})(\d{3})/, "($1)$2-$3");
  }
  if (response.length === 11) {
    response = response.replace(/^(\d{2})(\d{5})(\d{4})/, "($1)$2-$3");
  }

  return response;
}
function getTelefone2(params: GridValueGetterParams) {
  let ddd = params.getValue(params.id, "ddd_2");
  let telefone = params.getValue(params.id, "telefone_2");
  ddd = ddd ? ddd : "";
  telefone = telefone ? telefone : "";
  ddd = ddd?.toString().replace(/[^0-9]/g, "");
  telefone = telefone?.toString().replace(/[^0-9]/g, "");
  telefone = ddd + telefone;
  let response = telefone;
  if (response.length === 10) {
    response = response.replace(/^(\d{2})(\d{4})(\d{4})/, "($1)$2-$3");
  }
  if (response.length === 9) {
    response = response.replace(/^(\d{2})(\d{4})(\d{3})/, "($1)$2-$3");
  }
  if (response.length === 11) {
    response = response.replace(/^(\d{2})(\d{5})(\d{4})/, "($1)$2-$3");
  }

  return response;
}
function getEmail(params: GridValueGetterParams) {
  let email = params.getValue(params.id, "email");
  email = email?.toString();
  email = email ? email : "";
  email = email.toLowerCase();
  return email;
}

export default function TableCNPJ(response: any) {
  const [pageSize, setPageSize] = useState<number>(25);
  const [dataResponse, setDataResponse] = useState<any>(response.data);

  return (
    <ThemeProvider theme={theme}>
      <div style={{ height: 515, width: "100%" }}>
        <DataGrid
          rows={dataResponse}
          columns={columns}
          pageSize={pageSize}
          pagination
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[25, 50, 100]}
          checkboxSelection
          disableSelectionOnClick
          loading={response.loading}
          localeText={GridTraducao}
          getRowClassName={(params) => {
            let Situacao = params.getValue(params.id, "situacao");
            let varificador = "";
            if (Situacao === 2) {
              varificador = "active";
            } else {
              varificador = "desactive";
            }
            let css = "grid-color-" + varificador;
            return css;
          }}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </div>
    </ThemeProvider>
  );
}
