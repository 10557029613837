import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Moment from 'moment';
import Box from '@mui/material/Box';
import { green } from '@mui/material/colors';
import Icon from '@mui/material/Icon';


import 'moment/locale/pt-br';

import './style.scss';

import { Link } from 'react-router-dom';
import { RedesSociais } from '../../../../../../components/social';

//import  Face  from "../../../../../../../../image/social/Face.svg";


type Data = {
    nome?: string,
    cnpj?: string,
    identificador_socio?: string,
    cpf_cnpj?: string,
    qualificacao?: string,
    data_entrada_sociedade?: string,
    faixa_etaria?: string,
    nome_representante_legal?: string,
}
    

function verifyAge(age: string) {
  let response = '';
  
  switch (String(age)) {
    case '1':
      response = '0 - 10';
      break;
    case '2':
      response = '10 - 20';
      break;
    case '3':
      response = '20 - 30';
      break;
    case '4':
      response = '30 - 40';
      break;
    case '5':
      response = '40 - 50';
      break;
    case '6':
      response = '50 - 60';
      break;
    case '7':
      response = '60 - 70';
      break;
    case '8':
      response = '70 - 80';
      break;
    case '9':
      response = '80 - 90';
      break;
    case '10':
      response = '90 - 100';
      break;
    case '11':
      response = '100 - 110';
      break;
    default:
      response = ``;
  }
  return response
}

function formatAge(age: string) {
  let intervalAge = verifyAge(age);

  if (intervalAge?.length === 0 || intervalAge === undefined) {
    return '';
  }

  intervalAge = `Entre ${intervalAge} anos`;

  return intervalAge;
}


export default function SocioLayout(param: any) {
    let data = param.data? param.data : "";
    let dateTime = data.data_entrada_sociedade? data.data_entrada_sociedade : "";
    if(dateTime){
        dateTime = Moment(dateTime, "YYYY-MM-DD").format('DD/MM/YYYY');
    }
    
    const data_Formated: Data = {
        nome: data.nome,
        cpf_cnpj: data.cpf_cnpj,
        identificador_socio: data.identificador_socio,
        qualificacao: data.qualificacao + " - " + data.qualificacao_desc,
        data_entrada_sociedade: dateTime,
        faixa_etaria: formatAge(data.faixa_etaria),
        nome_representante_legal: data.nome_representante_legal
      };
  return (
    <Paper sx={{ flexGrow: 1, marginBottom: 1 , borderBottom: 1 + "px solid #cdcdcd", paddingTop: 2, paddingBottom: 2}} elevation={0} className="socio-list-group">
      <Grid container>
        <Grid item xs={12} container>
          <Grid item xs container direction="column">
            <Grid item xs >
              <Typography gutterBottom variant="subtitle1" component="a" sx={{ margin: 0}}>
              
              {data_Formated.cpf_cnpj? (
              <b><Link className="linkSocio" title="Visualizar dados do sócio" to={'/dashboard/socio/consultar/visualizar/' + data_Formated.nome + '/' + data_Formated.cpf_cnpj}>{data_Formated.nome}</Link></b>) : (<b>{data_Formated.nome}</b>)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs container direction="column" >

          {data_Formated.cpf_cnpj ? (
            <Grid item xs>
              <Typography gutterBottom variant="subtitle1" component="p" sx={{ margin: 0}}>
                <b>CPF/CNPJ: </b>{data_Formated.cpf_cnpj}
              </Typography>
            </Grid>
            ) : ""}
            {data_Formated.qualificacao ? (
            <Grid item xs>
              <Typography gutterBottom variant="subtitle1" component="p" sx={{ margin: 0}}>
                {data_Formated.qualificacao}
              </Typography>
            </Grid>
            ) : ""}
            {data_Formated.qualificacao ? (
            <Grid item xs>
              <Typography gutterBottom variant="subtitle1" component="p" sx={{ margin: 0}}>
              <b>Data de Entrada: </b> {data_Formated.data_entrada_sociedade}
              </Typography>
            </Grid>
            ) : ""}
            {data_Formated.qualificacao ? (
            <Grid item xs>
              <Typography gutterBottom variant="subtitle1" component="p" sx={{ margin: 0}}>
              <b>Faixa Etária: </b> {data_Formated.faixa_etaria}
              </Typography>
            </Grid>
            ) : ""}
             <Box
      sx={{
        '& > :not(style)': {
          m: 2,
        },
      }}
    >
      
       <RedesSociais name={data_Formated.nome}/>
    
      <Icon baseClassName="fas" className="fa-plus-circle" color="primary" />
      <Icon
        baseClassName="fas"
        className="fa-plus-circle"
        sx={{ color: green[500] }}
      />
      <Icon baseClassName="fas" className="fa-plus-circle" fontSize="small" />
      <Icon baseClassName="fas" className="fa-plus-circle" sx={{ fontSize: 30 }} />
    </Box>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
