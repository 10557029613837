import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import TransitionsModal from './Modal'
import { IBoardItem, useAppContext } from '../hooks/Context';

import { Menu } from '@szhsin/react-menu'

import MoreVertIcon from '@mui/icons-material/MoreVert';
import ForumIcon from '@mui/icons-material/Forum';
import DeleteIcon from '@mui/icons-material/Delete';
import { setPriority } from './utils/CardItem/setPriority';
import { deleteCard } from './utils/CardItem/deleteCard';
import { Button, IconButton, MenuItem, Tooltip } from '@mui/material';
import { getFomatedCNPJ } from '../../CNPJ/components/Table';
import { Link } from 'react-router-dom';

interface IRequest{
  data: IBoardItem;
  index: number;
}

interface IdataT extends IBoardItem{
  priorityColor: string;
    priorityTitle: string;
}


function CardItem({data, index}: IRequest) {
  let newID = data.id.toString()
  let newTitle = data.title
  let newCnpj = getFomatedCNPJ(data.cnpj)
  let newPorte = data.porte
  let newPosition = data.position

  const [newBoard, setNewBoard] = useAppContext()

  let newPriorityColor =
    data.priority === 0
      ? 'LabelPriorityCardGreen'
      : data.priority === 1
      ? 'LabelPriorityCardOrange'
      : 'LabelPriorityCardRed'

  let newPriorityTitle =
    data.priority === 0
      ? 'Baixa Prioridade'
      : data.priority === 1
      ? 'Média Prioridade'
      : 'Alta Prioridade'

  let newObs = data.observation
  let newInfo = data.info

  const dataT: IdataT = {
    id: newID,
    title: newTitle,
    cnpj: newCnpj,
    porte: newPorte,
    position: newPosition,
    priorityColor: newPriorityColor,
    priorityTitle: newPriorityTitle,
    observation: newObs,
    info: newInfo,
  }

  return (
    <Draggable index={index} draggableId={dataT.id.toString()}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="AroundCardGeral"
        >
          <label className={`LabelPriorityCard ${dataT.priorityColor}`}>
            {dataT.priorityTitle}
          </label>
          <Menu 
          menuButton={
          <Tooltip title="Opções">
                                <IconButton aria-label="settings">
                                  <MoreVertIcon />
                                </IconButton>
                              </Tooltip>
                              }
         transition>
            <MenuItem onClick={() => {setPriority(data.id, 0, newBoard, setNewBoard)}}>Baixa Prioridade</MenuItem>
            <MenuItem onClick={() => {setPriority(data.id, 1, newBoard, setNewBoard)}}>Média Prioridade</MenuItem>
            <MenuItem onClick={() => {setPriority(data.id, 2, newBoard, setNewBoard)}}>Alta Prioridade</MenuItem>
          </Menu>

          <h5 className="TitleCardItemText"><b>{dataT.title}</b></h5>
          <h5 className="TitleCardItemText"><b>CNPJ:</b> {dataT.cnpj}</h5>
          <h5 className="TitleCardItemText">
            <b>Porte da Empresa:</b> <br/>
            {dataT.porte}
          </h5>
          
          <div className="InsideAreaCard">
            <ul className="ListCardBottom">
              {data.info?.map((index: React.Key | null | undefined) => {
                return <li key={index}></li>
              })}
              <li className='MocalCall'>
                <TransitionsModal data={dataT}/>
                <span className="chatIcon"><ForumIcon fontSize='small'/>{data?.observation?.length}</span>
        
              </li>
              <li>
                <Link to={`/dashboard/cnpj/consultar/visualizar/${data.cnpj}`} target="_blank" style={{textDecoration: 'none'}}><Button variant="contained">Ver empresa</Button></Link>
              </li>
              <li>
                <Button onClick={() => {deleteCard(data.id, newBoard, setNewBoard)}}><DeleteIcon className='deleteIcon'/></Button>
              </li>
            </ul>
          </div>
        </div>
      )}
    </Draggable>
  )
}

export default CardItem
