
export const GridTraducao: any = {
  // Root
  noRowsLabel: 'Sem linhas',
  noResultsOverlayLabel: 'Nenhum resultado encontrado',
  errorOverlayDefaultLabel: 'Ocorreu um erro ;-;',

  // Density selector toolbar button text
  toolbarDensity: 'Densidade',
  toolbarDensityLabel: 'Densidade',
  toolbarDensityCompact: 'Compacto',
  toolbarDensityStandard: 'Padrão',
  toolbarDensityComfortable: 'Confortavel',

  // Columns selector toolbar button text
  toolbarColumns: 'Colunas',
  toolbarColumnsLabel: 'Selecionar coluna',

  // Filters toolbar button text
  toolbarFilters: 'Filtros',
  toolbarFiltersLabel: 'Mostrar Filtros',
  toolbarFiltersTooltipHide: 'Esconder Filtros',
  toolbarFiltersTooltipShow: 'Mostrar Filtros',
  toolbarFiltersTooltipActive: (count: number) =>
    count !== 1 ? `${count} filtros ativos` : `${count} filtro ativo`,

  // Export selector toolbar button text
  toolbarExport: 'Exportar',
  toolbarExportLabel: 'Exportar',
  toolbarExportCSV: 'Baixar Tabela',

  // Columns panel text
  columnsPanelTextFieldLabel: 'Procurar Coluna',
  columnsPanelTextFieldPlaceholder: 'Titulo da Coluna',
  columnsPanelDragIconLabel: 'Reordenar Coluna',
  columnsPanelShowAllButton: 'Mostrar tudo',
  columnsPanelHideAllButton: 'Esconder tudo',

  // Filter panel text
  filterPanelAddFilter: 'Adicionar Filtros',
  filterPanelDeleteIconLabel: 'Deletar',
  filterPanelOperators: 'Operadores',
  filterPanelOperatorAnd: 'e',
  filterPanelOperatorOr: 'Ou',
  filterPanelColumns: 'Colunas',
  filterPanelInputLabel: 'Valor do filtro',
  filterPanelInputPlaceholder: 'Valor',

  // Filter operators text
  filterOperatorContains: 'contem',
  filterOperatorEquals: 'igual',
  filterOperatorStartsWith: 'começa com',
  filterOperatorEndsWith: 'termina com',
  filterOperatorIs: 'É',
  filterOperatorNot: 'não é',
  filterOperatorAfter: 'depois de',
  filterOperatorOnOrAfter: 'is on or after',
  filterOperatorBefore: 'antes de',
  filterOperatorOnOrBefore: 'is on or before',
  filterOperatorIsEmpty: 'está vazio',
  filterOperatorIsNotEmpty: 'não está vazio',

  // Filter values text
  filterValueAny: 'any',
  filterValueTrue: 'true',
  filterValueFalse: 'false',

  // Column menu text
  columnMenuLabel: 'Menu',
  columnMenuShowColumns: 'Mostrar coluna',
  columnMenuFilter: 'Filtrar',
  columnMenuHideColumn: 'Esconder',
  columnMenuUnsort: 'Remover ordem',
  columnMenuSortAsc: 'ordenar por ASC',
  columnMenuSortDesc: 'ordenar por DESC',

  // Column header text
  columnHeaderFiltersTooltipActive: (count: number) =>
    count !== 1 ? `${count} filtros ativos` : `${count} filtro ativo`,
  columnHeaderFiltersLabel: 'Mostrar filtros',
  columnHeaderSortIconLabel: 'Ordenar',

  // Rows selected footer text
  footerRowSelected: (count: number) =>
    count !== 1
      ? `${count.toLocaleString()} linhas selecionadas`
      : `${count.toLocaleString()} linha selecionada`,

  // Total rows footer text
  footerTotalRows: 'Total de linhas:',

  // Total visible rows footer text
  footerTotalVisibleRows: (visibleCount: number, totalCount: number) =>
    `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,

  // Checkbox selection text
  checkboxSelectionHeaderName: 'Caixa de seleção',

  // Boolean cell text
  booleanCellTrueLabel: 'true',
  booleanCellFalseLabel: 'false',

  // Used core components translation keys
  MuiTablePagination: {},
};