/* Styles for MUI */
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';


import {
    Paper,
} from '@mui/material';


/* Functions */
export default function ListImport(response: any) {
    let data = response.data;
    let verifyEmpty = data[0].CNPJ ? data[0].CNPJ : data[0].cnpj;
    return (
        <Paper sx={{ flexGrow: 1, marginBottom: 1, borderBottom: 1 + "px solid #cdcdcd", padding: 2, boxShadow: "0px 4px 5px -1px rgb(0 0 0 / 20%)", display: verifyEmpty ? '' : 'none' }} elevation={0} className="socio-list-group" style={{ backgroundColor: "#fff" }}>
            <List
                sx={{
                    width: '100%',
                    maxWidth: 360,
                    bgcolor: 'background.paper',
                    position: 'relative',
                    overflow: 'auto',
                    maxHeight: 300,
                    '& ul': { padding: 0 },
                }}
                subheader={<li />}
            >

                {data.map((list: any, key: any) => {
                    if (list.CNPJ || list.cnpj) {
                        return (
                            <ListItem key={key}>
                                <ListItemText primary={list.CNPJ ? list.CNPJ : list.cnpj} />
                            </ListItem>
                        );
                    }
                })}
            </List>
        </Paper>
    );
}