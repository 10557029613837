import { useState } from 'react';
import axios from 'axios';


/* Database */
import { Database } from '../../../../../../../config/Database/database';

import {
    TextField,
    Autocomplete
} from '@mui/material';




type Consulta = [{
    uf?: string,
}]


/* Functions */
export default function InputList({ name, Namelabel, setForm, form }: any) {
    const [value, setValue] = useState<any>();
    const [inputValue, setInputValue] = useState('');
    const [Cidade, setCity] = useState<any>(cidades);


    const UrlConsulta = Database();
    const baseURL = UrlConsulta + "/consulta/municipio/";

    const updateField = (Value: any) => {
        let ValueDef = Value;
        ValueDef = ValueDef.id ? ValueDef.id : null;
        setForm({
            ...form,
            [name]: Value.id,
        });
        searchGet(Value.id);


    };


    async function searchGet(resp: any) {

        function convert(resp: any) {

            const newArray = resp.map((item: any) => {
                return ({ id: item.id, label: item.nome });
            });

            setCity(newArray);

        };


        const consultar: Consulta = [{
            uf: resp ? resp : "",
        }];

        const consultaFormat = consultar[0];


        await axios.get(baseURL, { params: consultaFormat })

            .then(response => {
                setCity(response.data);

                convert(response.data);


            }).catch(() => {
                setCity('');
            });
    }



    return (

        <Autocomplete
            value={value}
            onChange={(event: any, newValue: string | null) => {
                setValue(newValue);
                if (newValue) {
                    updateField(newValue);
                }
                else {
                    updateField('');
                }
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            options={estados}
            sx={{ width: '100%', padding: 0 }}
            renderInput={(params) => <TextField {...params}
                inputProps={{
                    ...params.inputProps,
                    autoComplete: "disabled", // disable autocomplete and autofill
                }}
                label={Namelabel} variant="outlined" sx={{ width: '100%' }} />}
        />

    );
}

const estados = [
    { id: "AC", label: "Acre" },
    { id: "AL", label: "Alagoas" },
    { id: "AP", label: "Amapá" },
    { id: "AM", label: "Amazonas" },
    { id: "BA", label: "Bahia" },
    { id: "CE", label: "Ceará" },
    { id: "DF", label: "Distrito Federal" },
    { id: "ES", label: "Espírito Santo" },
    { id: "GO", label: "Goiás" },
    { id: "MA", label: "Maranhão" },
    { id: "MT", label: "Mato Grosso" },
    { id: "MS", label: "Mato Grosso do Sul" },
    { id: "MG", label: "Minas Gerais" },
    { id: "PA", label: "Pará" },
    { id: "PB", label: "Paraíba" },
    { id: "PR", label: "Paraná" },
    { id: "PE", label: "Pernambuco" },
    { id: "PI", label: "Piauí" },
    { id: "RJ", label: "Rio de Janeiro" },
    { id: "RN", label: "Rio Grande do Norte" },
    { id: "RS", label: "Rio Grande do Sul" },
    { id: "RO", label: "Rondônia" },
    { id: "RR", label: "Roraima" },
    { id: "SC", label: "Santa Catarina" },
    { id: "SP", label: "São Paulo" },
    { id: "SE", label: "Sergipe" },
    { id: "TO", label: "Tocantins" }
]

const cidades = [
    { id: "AC", label: "Acre" },
    { id: "AL", label: "Alagoas" },
    { id: "AP", label: "Amapá" },
    { id: "AM", label: "Amazonas" },
    { id: "BA", label: "Bahia" },
    { id: "CE", label: "Ceará" },
    { id: "DF", label: "Distrito Federal" },
    { id: "ES", label: "Espírito Santo" },
    { id: "GO", label: "Goiás" },
    { id: "MA", label: "Maranhão" },
    { id: "MT", label: "Mato Grosso" },
    { id: "MS", label: "Mato Grosso do Sul" },
    { id: "MG", label: "Minas Gerais" },
    { id: "PA", label: "Pará" },
    { id: "PB", label: "Paraíba" },
    { id: "PR", label: "Paraná" },
    { id: "PE", label: "Pernambuco" },
    { id: "PI", label: "Piauí" },
    { id: "RJ", label: "Rio de Janeiro" },
    { id: "RN", label: "Rio Grande do Norte" },
    { id: "RS", label: "Rio Grande do Sul" },
    { id: "RO", label: "Rondônia" },
    { id: "RR", label: "Roraima" },
    { id: "SC", label: "Santa Catarina" },
    { id: "SP", label: "São Paulo" },
    { id: "SE", label: "Sergipe" },
    { id: "TO", label: "Tocantins" }
]
