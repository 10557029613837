import { useState, useEffect } from "react";
import axios from "axios";

/* DataBase */
import { Database } from "../../../../../config/Database/database";

/* Components */
import TableCNPJ from "../CNPJ/components/Table";

/* Styles */
import { Paper, Button } from "@mui/material";

import CachedIcon from "@mui/icons-material/Cached";
import { parseCookies } from "nookies";

//const {user} = useAuth();
/* Functions */
export function SyncMinhaLista() {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataTable, setData] = useState<any>([]);

  const UrlConsulta = Database();

  const baseURL = UrlConsulta + "/consulta/";

  async function consultarDB(valor: any) {
    let responseDB: any = {};
    if (valor) {
      await axios
        .get(baseURL, { params: { nomeFantasiaCNPJ: valor } })
        .then((response) => {
          responseDB = response.data[0];
        })
        .catch(() => {
          setLoading(false);
        });
    }
    return responseDB;
  }

  const GerarTabela = async (ArrayTable: any) => {
    let table = ArrayTable;
    let rows: any = [];

    for (let i = 0; i < table.length; i++) {
      let newRows = await consultarDB(table[i]);
      rows[i] = newRows;
    }
    localStorage.setItem("MinhaLista", JSON.stringify(rows as any));
    return rows;
  };

  const MapTable = (value: string[]) => {
    const table = value;

    let rowData: any = [];

    table.map((lista: any, key: any) => {
      let filter = lista.CNPJ ? lista.CNPJ : lista.cnpj;

      if (filter) {
        let cnpj = filter!.toString().replace(/[^0-9]/g, "");
        return (rowData[key] = cnpj);
      }
    });

    return rowData;
  };

  function consultarLista() {
    const { "b2bAuth.user": user } = parseCookies();
    let userIdLocal = user;
    let listaURL = UrlConsulta + "/user/list/";

    let ListaNoFormated: any = [];
    console.log("consultando lista");

    if (userIdLocal) {
      axios
        .get(listaURL, { params: { userID: userIdLocal } })
        .then(async (response) => {
          ListaNoFormated = response.data;
          console.log(response.data);
          setLoading(true);

          if (ListaNoFormated.length > 0) {
            let mapTable = await MapTable(ListaNoFormated);

            if (mapTable.length > 0) {
              let formatedTable = await GerarTabela(mapTable);

              const removeNull = formatedTable.filter((x: any) => x != null);
              console.log(removeNull);

              setData(removeNull);
            }
            setLoading(false);
          } else {
            localStorage.setItem("MinhaLista", JSON.stringify([] as any));
            setData([]);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log("Algo deu errado");
        });
    }
  }

  useEffect(() => {
    consultarLista();
  }, []);

  return (
    <div>
      <Paper
        sx={{
          flexGrow: 1,
          marginBottom: 1,
          borderBottom: 1 + "px solid #cdcdcd",
          paddingTop: 2,
          paddingBottom: 2,
          boxShadow: "0px 4px 5px -1px rgb(0 0 0 / 20%)",
        }}
        elevation={0}
        className="socio-list-group"
        style={{ backgroundColor: "#fff" }}
      >
        <Button
          variant="outlined"
          className="ButtonLista"
          onClick={() => {
            consultarLista();
          }}
          sx={{ marginLeft: "20px", color: "black" }}
          endIcon={<CachedIcon sx={{ color: "black" }} />}
        >
          Recarregar Lista
        </Button>
        <p style={{ marginLeft: "20px" }}>
          *Caso não apareça a lista, por favor aperte o botão a cima
        </p>
      </Paper>
      {console.log(dataTable)}
      <TableCNPJ
        data={dataTable}
        loading={loading}
        export={true}
        remove={[true, consultarLista]}
      />
    </div>
  );
}
