import { parseCookies } from "nookies";
import jwt_decode from "jwt-decode";

function verifyUserLogin() {
    try {
        const { "b2bAuth.user": user, "b2bAuth.token": token } = parseCookies();
        const decoded: any = jwt_decode(token);
        let response = (user === decoded?.sub);

        return response;
    }
    catch {
        return false;
    }
}


export { verifyUserLogin }