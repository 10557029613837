import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom'
import { AuthProvider } from '../Contexts/Auth';

import { LoginPage } from '../../pages/Login';
import { Dashboard } from '../../pages/Dashboard';

import { DashboardInicio } from '../../pages/Dashboard/Subpages/Inicio';
import { Consultar } from '../../pages/Dashboard/Subpages/Consultar/CNPJ';
import { Visualizar } from '../../pages/Dashboard/Subpages/Consultar/CNPJ/Visualizar';
import { ConsultarLista } from '../../pages/Dashboard/Subpages/Consultar/ImportarLista';
import { VisualizarSocio } from '../../pages/Dashboard/Subpages/Consultar/Socios/Visualizar';
import { MinhaLista } from '../../pages/Dashboard/Subpages/Consultar/MinhaLista/';
import { ThemeTemplateProvider } from '../Contexts/Theme';
import { Manutencao } from '../../pages/Manutencao';
import { CRM } from '../../pages/Dashboard/Subpages/Consultar/CRM';
import { verifyUserLogin } from '../Hooks/verifyLogin';


if (!localStorage.getItem("tableSearch")) {
  localStorage.setItem("tableSearch", '[]');
}

const PrivateRoute = ({ component: Component, ...rest }) => {

  try {
    let verify = verifyUserLogin();

    return (
      <Route {...rest} render={props => (
        (verify) ? (
          <Component {...props} />
        ) : (<Redirect to={{ pathname: '/', state: { from: props.location } }} />)
      )
      }
      />)
  }
  catch (err) {
    return (
      <Route {...rest} render={props => (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />)} />)

  }
};

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <ThemeTemplateProvider>
          <AuthProvider>
            <Route path="/" exact component={LoginPage} />

            <PrivateRoute path="/dashboard/">
              <Dashboard>
                <PrivateRoute exact path="/dashboard/" component={DashboardInicio} />
                <PrivateRoute exact path="/dashboard/cnpj/consultar" component={Consultar} />
                <PrivateRoute exact path="/dashboard/cnpj/consultar/visualizar/:id" component={Visualizar} />
                <PrivateRoute exact path="/dashboard/socio/consultar/visualizar/:name/:cpf" component={VisualizarSocio} />
                <PrivateRoute exact path="/dashboard/cnpj/importarlista" component={ConsultarLista} />
                <PrivateRoute exact path="/dashboard/cnpj/minhalista" component={MinhaLista} />
                <PrivateRoute exact path="/dashboard/cnpj/crm" component={CRM} />
                <PrivateRoute exact path="/dashboard/comunidade" component={Manutencao} />
                <PrivateRoute exact path="/dashboard/afiliacao" component={Manutencao} />
              </Dashboard>
            </PrivateRoute>

          </AuthProvider>
        </ThemeTemplateProvider>

      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
