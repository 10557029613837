import { Button, CircularProgress, Grid, Paper } from "@mui/material";
import { useState } from "react";
import SaveButton from "./components/saveButton";
import { useAppContext } from "./hooks/Context";
import AddIcon from '@mui/icons-material/Add';
import Boards from "./components/Boards";
import { createBoardAction } from "./components/utils/Boards/createBoardAction";


import '@szhsin/react-menu/dist/index.css'
import '@szhsin/react-menu/dist/transitions/slide.css';

export function KanbanHome(){
    const [newBoard, setNewBoard, loading] = useAppContext();

    return (
        <div className="GeneralAreaKanbanContent">
        <div className="GeneralKanbanHeader blockTop">
        <div className="HeaderTextArea">
          <h4 className="">CRM (beta)</h4>
        </div>
        <ul className="CreateNewColumnList">
          <li>
          <SaveButton />
          </li>

          <li>
            <Button onClick={() => createBoardAction(newBoard, setNewBoard)}>
              <AddIcon className="IconeMaisCreateNewBoard"/>
            </Button>
          </li>
        </ul>
      </div>
      {!loading ? <Boards/> : (
        <Paper sx={{flexGrow: 1, marginTop: 1, marginBottom: 1, borderBottom: 1 + "px solid #cdcdcd", paddingTop: 2, paddingBottom: 2, boxShadow: "0px 4px 5px -1px rgb(0 0 0 / 20%)" }} elevation={0} className="socio-list-group" style={{ backgroundColor: "#fff" }}>
        <Grid container>
        <Grid item xs={12} container sx={{ justifyContent: "left", alignItems: "center", marginLeft: "20px", flexDirection: "column" }}>
            <h2>Carregando dados</h2>
            <CircularProgress size={40}/>
        </Grid>
    </Grid>
    </Paper>
      )}
      </div>
    )
}