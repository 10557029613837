import axios, { AxiosError } from 'axios'
import { parseCookies, setCookie } from 'nookies'
import { ServerAPI } from '../config/Database/API/server';

let isRefreshing = false;
let failedRequestsQueue: any = []

export function setupApiClient(ctx = undefined) {
    let cookies = parseCookies(ctx)

    const api = axios.create({
        baseURL: ServerAPI.url,
        headers: {
            Authorization: `Bearer ${cookies['b2bAuth.token']}`
        }
    })
    let verify = "";
    api.interceptors.response.use(response => {
        verify = response.headers['content-type']
        return response;
    }, (error: AxiosError) => {

        if (error.response?.status === 401) {
            //alert(error.response.data.message);

            cookies = parseCookies(ctx)

            const { 'b2bAuth.refreshToken': refreshToken } = cookies;
            const originalConfig = error.config;

            if (!isRefreshing) {
                isRefreshing = true;

                api.post('/refresh-token', {
                    token: refreshToken
                }).then((response: any) => {
                    const { token, refresh_token } = response.data;

                    setCookie(ctx, 'b2bAuth.token', token, {
                        maxAge: 60 * 60 * 24 * 30,
                        path: '/'
                    })

                    setCookie(ctx, 'b2bAuth.refreshToken', refresh_token, {
                        maxAge: 60 * 60 * 24 * 30, // 30 days
                        path: '/'
                    })

                    failedRequestsQueue.forEach((request: any) => request.onSuccess(token));
                    failedRequestsQueue = []

                }).catch(err => {
                    failedRequestsQueue.forEach((request: any) => request.onFailure(err));
                    failedRequestsQueue = []


                }).finally(() => {
                    isRefreshing = false;
                });
            }

            return new Promise((resolve, reject) => {
                failedRequestsQueue.push({
                    onSuccess: (token: string) => {
                        originalConfig.headers =
                        {
                            Authorization: `Bearer ${token}`
                        }

                        if (verify === 'application/json; charset=utf-8') {

                            if (originalConfig.data) {
                                let value = JSON.parse(originalConfig.data);

                                if (value) {
                                    originalConfig.data = value;
                                }
                            }

                        }
                        resolve(api(originalConfig))
                    },
                    onFailure: (err: AxiosError) => {
                        reject(error.response?.data.message)
                    }
                })
            })

        }

        return Promise.reject(error)
    })
    return api;
}