import { IBoardData } from "../../../hooks/Context"

export const editFunctionClick = async (id: string | number, newBoard: IBoardData[], setEditBoard: Function) => {
  const val = (document.getElementById("boardEditForm") as HTMLInputElement).value
  const boardId = id

  if (boardId !== null) {
    let newBoardData = await JSON.parse(JSON.stringify(newBoard));
    await newBoardData.find((p: IBoardData) => p.id === id && (p.name = val.trim()))
    setEditBoard(false)

    return newBoardData
  }
}