

import './style.scss'

type User = {
    img?: string,
    name?: string | null,
    cargo?: string,
    email?: string | null,
    validate?: Date | null

}

export function UserAside(props: User) {
    let email = props.email ? props.email : "";
    let nome = props.name ? props.name : "";
    let validate = props.validate ? new Date(props.validate).toLocaleDateString("pt-BR"): "";
    
    if (nome === email) {
        email = "";
    }
    if(nome.length === 0){
        nome = email;
    }
    return (
        <div className="user-perfil">
            <img src={props.img} alt="" />
            <div className="user--perfil--div">
                <div className="user-perfil--name">{ }
                    {props.name ? props.name : "Carregando..."}</div>
                {validate ? (<div className="user-perfil--position">Validade: {validate}</div>)
                    : ""}
                {/* <div className="user-perfil--position">{props.cargo? props.cargo : "Carregando..."}</div> */}
            </div>

        </div>
    )
}