import { useState, useEffect } from "react";
import axios from "axios";

/* DataBase */
import { Database } from "../../../../../config/Database/database";

import TableCNPJ from "./components/table";
import { parseCookies } from "nookies";

//const {user} = useAuth();
/* Functions */
export function AsyncMinhaLista(response: any) {
  const [dataTableRow, setDataRow] = useState<any>(
    response.data ? response.data : []
  );
  const UrlConsulta = Database();

  async function consultarLista() {
    const { "b2bAuth.user": user } = parseCookies();
    let userIdLocal = user;
    let listaURL = UrlConsulta + "/user/list/";

    let ListaNoFormated: any = [];
    console.log("consultou");
    if (userIdLocal) {
      let value = await axios
        .get(listaURL, { params: { userID: userIdLocal } })
        .then(async (response) => {
          ListaNoFormated = response.data;
          return ListaNoFormated;
        });

      return value;
    }
  }

  return (
    <div>
      {dataTableRow.length > 0 ? (
        <TableCNPJ
          data={dataTableRow}
          count={dataTableRow.length}
          export={true}
          remove={[true, consultarLista]}
        />
      ) : (
        ""
      )}
    </div>
  );
}
